import React from 'react'
import type { FC } from 'react'

interface BlackLogoProps {
  [key: string]: any
}

const BlackLogo: FC<BlackLogoProps> = (props) => <img alt="Logo" height={40} src="/static/images/black_logo.png" {...props} />

export default BlackLogo
