import { gql } from '@apollo/client'

export const EQUIPMENTS = gql`
  query ($data: EquipmentsInput) {
    equipments(data: $data) {
      count
      results {
        brand
        modal
        serial_number
        physical_location
        area_servicing
        custom_fields
        equipment_type_id
        warranty_link
        site_id
        id
        equipment_type {
          name
          equipment_type_fields {
            id
            field_name
            field_type
          }
        }
      }
    }
  }
`
export const EQUIPMENT_TYPES = gql`
  query ($data: EquipmentQuery!) {
    equipmentTypes(data: $data) {
      results {
        id
        name
        equipment_type_fields {
          field_name
          field_type
          id
        }
      }
      count
    }
  }
`

export const CREATE_EQUIPMENT_TYPE = gql`
  mutation ($data: EquipmnetTypeInput!) {
    createEquipmentType(data: $data) {
      id
      name
      equipment_type_fields {
        id
        field_name
        field_type
      }
    }
  }
`

export const UPDATE_EQUIPMENT = gql`
  mutation ($data: EquipmentUpdateInput!, $id: ID!) {
    updateEquipment(data: $data, id: $id) {
      brand
      modal
      serial_number
      physical_location
      area_servicing
      custom_fields
      warranty_link
      equipment_type_id
      site_id
      id
      equipment_type {
        name
      }
    }
  }
`
export const GENERATE_WARRANTY_LINK = gql`
  mutation ($equipment_id: String!, $file_name: String!) {
    generateWarrantyLink(equipment_id: $equipment_id, file_name: $file_name) {
      sasLink
      pathToFile
    }
  }
`
export const DELETE_WARRANTY_LINK = gql`
  mutation ($id: ID!) {
    deleteWarrantyLink(id: $id)
  }
`
export const GET_SIGNED_DOWNLOAD_LINK = gql`
  query getSignedDownloadLink($id: ID!) {
    getSignedDownloadLink(id: $id)
  }
`
