import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { Box, LinearProgress, styled, Typography } from '@mui/material'
import { useMutation } from '@apollo/client'
import { QB_LOGIN_TOKENS } from 'src/apollo/queries/connections'
import toast from 'react-hot-toast'
import useAuth from 'src/hooks/useAuth'

const PREFIX = 'VerifyConnection'

const classes = {
  root: `${PREFIX}-root`,
  loader: `${PREFIX}-loader`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2)
  },
  [`& .${classes.loader}`]: {
    marginTop: 20,
    justifyContent: 'center',
    display: 'flex'
  }
}))

const QuickbooksCallbackPage = (_props: any) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const state = searchParams.get('state')

  const [requestQBTokens] = useMutation(QB_LOGIN_TOKENS)
  const auth = useAuth()

  const verifyUserFingerprint = async (userId: string, state: string): Promise<boolean> =>
    crypto.subtle.digest('SHA-1', new TextEncoder().encode(userId)).then((hash) => {
      const hashString = Array.from(new Uint8Array(hash))
      return state === hashString.join('')
    })

  const redirectError = () => navigate('/errors/error-404')

  useEffect(() => {
    if (!state || !code) redirectError()

    const isValidCaller = verifyUserFingerprint(auth.user.id, state)
    if (!isValidCaller) redirectError()
    requestQBTokens({ variables: { data: { redirectUrl: window.location.href } } })
      .then(({ data }) => {
        const { authenticateAndSyncQbTokens } = data
        if (authenticateAndSyncQbTokens.status === 'authenticated') toast.success('Successfully Logged in!')
        if (authenticateAndSyncQbTokens.status === 'unauthenticated') toast.error('Failed to Log in to QuickBooks')
        if (authenticateAndSyncQbTokens.status === 'pending') toast.loading('Error, Please try again in a few minutes')
      })
      .catch(() => {
        toast.error('Failed to get tokens')
      })
      .finally(() => {
        setInterval(() => {
          window.close()
        }, 2000)
      })
  }, [])

  return (
    <Root className={classes.root}>
      <Box>
        <Typography variant="h4" gutterBottom>
          Verifying Connection to Quickbooks...
        </Typography>
        <LinearProgress className={classes.loader} />
      </Box>
    </Root>
  )
}

export default QuickbooksCallbackPage
