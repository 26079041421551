import { gql } from '@apollo/client'

export const TRANSACTION = gql`
  query ($id: ID!) {
    transaction(id: $id) {
      id
      type
      job_id
    }
  }
`

export const TOTAL_TRANSACTION_COST = gql`
  query ($job_id: ID!) {
    totalTransactionCost(job_id: $job_id)
  }
`

export const TRANSACTIONS = gql`
  query ($data: TransactionsQuery) {
    transactions(data: $data) {
      count
      results {
        id
        type
        job_id
        created_at
        date
        vendor_id
        overhead_transaction
        doc_number
        payment_account
        payment_type
        transaction_materials {
          material_id
          quantity
          total_cost
          material {
            name
          }
        }
        transaction_receipts {
          id
          file_name
          url
        }
        created_date
        job {
          name
        }
        vendor {
          first_name
          last_name
          display_name
        }
        transaction_lines {
          id
          transaction_id
          amount
          line_type
          account_id
          item_id
          job_id
          item_qty
          rate
          description
        }
      }
    }
  }
`

export const MATERIALS_BY_VENDOR_ID = gql`
  query ($data: MaterialsVendorQuery!) {
    materialsByVendorId(data: $data) {
      count
      results {
        id
        name
      }
    }
  }
`

export const TOTAL_EXPENSES = gql`
  query ($data: TotalExpensesQuery) {
    totalExpenses(data: $data) {
      totalExpenses
    }
  }
`

export const CREATE_TRANSACTION = gql`
  mutation ($data: TransactionCreateInput) {
    createTransaction(data: $data) {
      id
      type
      job_id
      created_date
      date
      transaction_materials {
        material_id
        material {
          name
        }
      }
      transaction_receipts {
        id
      }
    }
  }
`

export const UPDATE_TRANSACTION = gql`
  mutation ($data: TransactionUpdateInput!, $id: ID!) {
    updateTransaction(data: $data, id: $id) {
      id
      type
      job_id
      transaction_materials {
        material_id
        material {
          name
        }
      }
      transaction_receipts {
        id
      }
    }
  }
`

export const UPDATE_TRANSACTION_SINGLE = gql`
  mutation ($data: TransactionUpdateInput!, $id: ID!) {
    updateTransactionSingle(data: $data, id: $id) {
      id
      type
      job_id
      transaction_materials {
        material_id
        material {
          name
        }
      }
      transaction_receipts {
        id
      }
    }
  }
`

export const GENERATE_RECEIPT_UPLOAD_LINK = gql`
  mutation ($transactionId: ID!) {
    generateReceiptUploadLink(transactionId: $transactionId) {
      sasLink
      pathToFile
    }
  }
`

export const VIEW_RECEIPT_LINK = gql`
  mutation ($receiptId: ID!) {
    viewReceiptLink(receiptId: $receiptId)
  }
`

export const CREATE_TRANSACTION_RECEIPT = gql`
  mutation ($data: TransactionReceiptCreateInput!) {
    createTransactionReceipt(data: $data) {
      id
      transaction_id
      url
    }
  }
`

export const DELETE_TRANSACTION_RECEIPT = gql`
  mutation ($id: ID!) {
    deleteTransactionReceipt(id: $id)
  }
`

export const DELETE_TRANSACTION_LINE = gql`
  mutation ($id: ID!) {
    deleteTransactionLine(id: $id)
  }
`

export const DELETE_TRANSACTION = gql`
  mutation ($id: ID!) {
    deleteTransaction(id: $id)
  }
`
