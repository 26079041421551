import { endOfMonth, endOfYear, format } from 'date-fns'
import { either, isEmpty, isNil, reject } from 'ramda'

export const getInitials = (name = ''): string =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('')

type TrucateOptions = { length?: number; omission?: string }
export const truncate = (str: string, options?: TrucateOptions) => {
  const length = options?.length || 30
  const omission = options?.omission || '...'
  return str.length > length ? `${str.substring(0, length)}${omission}` : str
}

export const formatPhoneNumber = (phoneNumber) => {
  const input = phoneNumber ? phoneNumber.replace(/\D/g, '') : ''
  const size = input.length
  if (size > 6) {
    return `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6)}`
  }
  if (size > 3) {
    return `(${input.slice(0, 3)}) ${input.slice(3)}`
  }
  if (size > 0) {
    return `(${input}`
  }
  return input
}
export const formatMoney = Intl.NumberFormat('en-US')

export const getMostRecentPayPeriod = () => {
  const date = new Date()
  const currentDayOfMonth = parseFloat(format(date, 'd'))
  const currentMonthOfYear = parseFloat(format(date, 'M')) - 1
  const currentYear = parseFloat(format(date, 'yyyy'))
  if (currentDayOfMonth > 15) {
    return {
      startDate: new Date(currentYear, currentMonthOfYear, 1),
      endDate: new Date(currentYear, currentMonthOfYear, 15)
    }
  }
  if (currentMonthOfYear !== 1) {
    const start = new Date(currentYear, currentMonthOfYear - 1, 16)
    return {
      startDate: start,
      endDate: endOfMonth(start)
    }
  }
  const start = new Date(currentYear - 1, 12, 16)
  return {
    startDate: start,
    endDate: endOfYear(start)
  }
}

export const dotColor = (label: string) => {
  if (label === 'open') return '#7F56D9'
  if (label === 'completed') return '#6CC5A9'
  if (label === 'on hold') return '#FFDC5E'
  return '#155EEF'
}
export const backgroundColor = (label: string) => {
  if (label === 'open') return '#F9F5FF'
  if (label === 'completed') return '#EDFCF2'
  if (label === 'on hold') return '#FFFAEB'
  return '#EFF8FF'
}

export const objectRemoveNullsAndEmpty = (obj) => reject(either(isNil, isEmpty), obj)
