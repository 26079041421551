import { gql } from '@apollo/client'

export const SYNC_TIME_ENTRIES = gql`
  mutation ($startDate: Date!, $endDate: Date!) {
    syncTimeEntries(startDate: $startDate, endDate: $endDate)
  }
`

export const TOTAL_TIME = gql`
  query ($job_id: ID!) {
    totalTime(job_id: $job_id)
  }
`

export const USER_WEEKLY = gql`
  query ($start_date: Date!, $end_date: Date!, $user_id: ID!, $take: Int!, $skip: Int!) {
    weeklyTotals(start_date: $start_date, end_date: $end_date, user_id: $user_id, take: $take, skip: $skip) {
      count
      results {
        id
        duration_seconds
        job_id
        clock_in
        clock_out
        job {
          name
        }
        time_entry_request {
          id
          status
        }
      }
    }
  }
`

export const USER_WEEKLY_TOTALS = gql`
  query ($start_date: Date!, $end_date: Date!, $today: Date!, $user_id: ID!) {
    weeklyClockInTotals(start_date: $start_date, end_date: $end_date, today: $today, user_id: $user_id) {
      weekly
      daily
    }
  }
`

export const CURRENT_RUNNING_CLOCKS = gql`
  query {
    currentRunningClocks {
      id
      job_id
      clock_in
      clock_out
      job {
        name
        customer_site {
          id
          site {
            address
          }
        }
      }
    }
  }
`

export const CLOCK_IN = gql`
  mutation ($job_id: String!, $note: String) {
    clockIn(job_id: $job_id, note: $note) {
      id
    }
  }
`

export const CLOCK_OUT = gql`
  mutation ($note: String) {
    clockOut(note: $note) {
      id
    }
  }
`

export const CREATE_TIME_ENTRY = gql`
  mutation ($timeEntry: TimeEntryCreateInput!) {
    createTimeEntry(timeEntry: $timeEntry) {
      id
      job_id
      user_id
      note
      clock_in
      clock_out
      quickbooks_id
      sync_token
    }
  }
`

export const GET_TIME_ENTRY_BY_ID = gql`
  query ($id: ID!) {
    getTimeEntryById(id: $id) {
      id
      job_id
      user_id
      job {
        id
        name
        status
      }
    }
  }
`

export const TIME_ENTRIES = gql`
  query ($userId: String, $startDate: Date!, $endDate: Date!) {
    timeEntries(userId: $userId, startDate: $startDate, endDate: $endDate) {
      id
      job_id
      time_entry_request {
        id
        status
      }
      job {
        id
        name
        status
        type
        description
        issue
        additional_information
        issue_details
        time_entries {
          note
          user {
            first_name
            last_name
          }
          clock_in
          clock_out
        }
        customer_site {
          customer {
            id
            first_name
            last_name
          }
          site {
            name
            address
          }
        }
      }
      user_id
      user {
        id
        email
        role
        inactive
        first_name
        last_name
        phone
        employee_id
      }
      note
      clock_in
      clock_out
      quickbooks_id
      sync_token
    }
  }
`

export const TOTAL_FINANCIALS = gql`
  query ($start_date: Date!, $end_date: Date!) {
    financialTotals(start_date: $start_date, end_date: $end_date) {
      total_hours
      total_cost
      total_overtime_hours
      total_regular_hours
    }
  }
`
export const PAGINATED_JOB_TIME_ENTRIES = gql`
  query ($data: PaginatedJobTimeEntriesInput!) {
    paginatedJobTimeEntries(data: $data) {
      count
      results {
        job_id
        user {
          first_name
          last_name
        }
        clock_out
        clock_in
        id
        note
        user_id
        quickbooks_id
        sync_token
        time_entry_request {
          id
          status
        }
      }
    }
  }
`

export const DELETE_TIME_ENTRY = gql`
  mutation ($id: ID!) {
    deleteTimeEntry(id: $id)
  }
`

export const UPDATE_TIME_ENTRY = gql`
  mutation ($timeEntry: TimeEntryUpdateInput!, $id: ID!) {
    updateTimeEntry(timeEntry: $timeEntry, id: $id) {
      id
    }
  }
`
