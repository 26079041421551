import { Suspense, lazy } from 'react'
import { Navigate, RouteObject } from 'react-router'
import DashboardLayout from 'src/layouts/DashboardLayout'
import AuthLayout from 'src/layouts/AuthLayout'
import LoadingScreen from 'src/components/LoadingScreen'
import GuestGuard from 'src/components/GuestGuard'
import AuthGuard from 'src/components/AuthGuard'
import AdminGuard from './components/AdminGuard'
import QuickbooksCallbackPage from './views/settings/QbConnectionCallbackPage'

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
)

const NotFound = Loadable(lazy(() => import('src/views/error/NotFoundView')))
const UserProfile = Loadable(lazy(() => import('src/views/userProfile/UserProfile')))
const UserList = Loadable(lazy(() => import('src/views/userList/UserList')))
const ResetPassword = Loadable(lazy(() => import('src/views/passwordReset/ResetPassword')))
const Login = Loadable(lazy(() => import('src/views/userLogin/Login')))
const Settings = Loadable(lazy(() => import('src/views/settings')))
const Permissions = Loadable(lazy(() => import('src/views/permissions/Permissions')))
const CustomerList = Loadable(lazy(() => import('src/views/customerList/customerList')))
const SiteList = Loadable(lazy(() => import('src/views/siteList/siteList')))
const CustomerDetails = Loadable(lazy(() => import('src/views/customerDetails/customerDetails')))
const JobList = Loadable(lazy(() => import('src/views/jobList/jobList')))
const JobDetails = Loadable(lazy(() => import('src/views/jobDetails/jobDetails')))
const ReportingPage = Loadable(lazy(() => import('src/views/report/ReportingPage')))
const ClockPage = Loadable(lazy(() => import('src/views/clocks/Clock')))
const Expenses = Loadable(lazy(() => import('src/views/expenses/Expenses')))
const HomePage = Loadable(lazy(() => import('src/views/dashboardView/HomePage')))
const Schedule = Loadable(lazy(() => import('src/views/schedule/index')))
const Inventory = Loadable(lazy(() => import('src/views/inventory/Inventory')))
const Estimates = Loadable(lazy(() => import('src/views/estimates/Estimates')))
const Estimate = Loadable(lazy(() => import('src/views/estimate/Estimate')))
const Quotes = Loadable(lazy(() => import('src/views/quotes/Quotes')))
const Quote = Loadable(lazy(() => import('src/views/quote/Quote')))
const Vendors = Loadable(lazy(() => import('src/views/vendors/Vendors')))
const Vendor = Loadable(lazy(() => import('src/views/vendor/Vendor')))
const Invoices = Loadable(lazy(() => import('src/views/invoices/Invoices')))
const Invoice = Loadable(lazy(() => import('src/views/invoice/Invoice')))
const Payments = Loadable(lazy(() => import('src/views/payments/Payments')))
const Payment = Loadable(lazy(() => import('src/views/payment/Payment')))
const ContactUs = Loadable(lazy(() => import('src/views/support')))
const TermsAndConditions = Loadable(lazy(() => import('src/views/terms')))

const routes: RouteObject[] = [
  {
    path: 'contactus',
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: '',
        element: <ContactUs />
      }
    ]
  },
  {
    path: 'terms-and-conditions',
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: '',
        element: <TermsAndConditions />
      }
    ]
  },
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'resetpassword',
        element: <ResetPassword />
      }
    ]
  },
  {
    path: '/admin',
    element: (
      <AdminGuard>
        <DashboardLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: '/admin',
        element: <Navigate to="/admin/users" replace />
      },
      {
        path: '/admin/users',
        element: <UserList />
      },
      {
        path: '/admin/settings',
        element: <Settings />
      },
      {
        path: '/admin/settings/qbconnect',
        element: <QuickbooksCallbackPage />
      },
      {
        path: '/admin/permissions',
        element: <Permissions />
      }
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/user/:id',
        element: <UserProfile />
      },
      {
        path: '/user/:id/:tab',
        element: <UserProfile />
      },
      {
        path: '/customers',
        element: <CustomerList />
      },
      {
        path: '/job/:id',
        element: <JobDetails />
      },
      {
        path: '/job/:id/:tab',
        element: <JobDetails />
      },
      {
        path: '/customer/:id',
        element: <CustomerDetails />
      },
      {
        path: '/customer/:id/:tab',
        element: <CustomerDetails />
      },
      {
        path: '/sites',
        element: <SiteList />
      },
      {
        path: '/jobs',
        element: <JobList />
      },
      {
        path: '/timeentries',
        element: <ReportingPage />
      },
      {
        path: '/clocks',
        element: <ClockPage />
      },
      {
        path: '/inventory/:tab',
        element: <Inventory />
      },
      {
        path: '/estimates',
        element: <Estimates />
      },
      {
        path: '/estimate/:id',
        element: <Estimate />
      },
      {
        path: '/quotes',
        element: <Quotes />
      },
      {
        path: '/quote/:id',
        element: <Quote />
      },
      {
        path: '/vendors',
        element: <Vendors />
      },
      {
        path: '/vendor/:id',
        element: <Vendor />
      },
      {
        path: '/invoices',
        element: <Invoices />
      },
      {
        path: '/invoice/:id',
        element: <Invoice />
      },
      {
        path: '/payments',
        element: <Payments />
      },
      {
        path: '/payment/:id',
        element: <Payment />
      },
      {
        path: '/purchases',
        element: <Expenses />
      },
      {
        path: '/schedule',
        element: <Schedule />
      },
      {
        path: '/*',
        element: <Navigate to="/errors" replace />
      }
    ]
  },
  {
    path: '/errors',
    element: <NotFound />
  }
]

export default routes
