export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  JSONObject: any
  PhoneNumber: any
}

export type AccessTokenResponse = {
  __typename?: 'AccessTokenResponse'
  token: Scalars['String']
  user: User
}

export type Account = {
  __typename?: 'Account'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  sub_account?: Maybe<Scalars['Boolean']>
  sub_name?: Maybe<Scalars['String']>
}

export type AccountQuery = {
  classification?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  take?: InputMaybe<Scalars['Int']>
}

export type AddTechInput = {
  job_schedule_id: Scalars['ID']
  user_id: Scalars['ID']
}

export type Assembly = {
  __typename?: 'Assembly'
  active?: Maybe<Scalars['Boolean']>
  adjusted_cost?: Maybe<Scalars['Float']>
  assembly_items?: Maybe<Array<Maybe<AssemblyItem>>>
  cost?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  estimate_id?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
  manufacturer?: Maybe<Scalars['String']>
  model?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  sales_price?: Maybe<Scalars['Float']>
  tag?: Maybe<Scalars['String']>
  type?: Maybe<AssemblyType>
  visual_id?: Maybe<Scalars['String']>
}

export type AssemblyInput = {
  active?: InputMaybe<Scalars['Boolean']>
  adjusted_cost?: InputMaybe<Scalars['Float']>
  assembly_items?: InputMaybe<Array<InputMaybe<AssemblyItemInput>>>
  cost?: InputMaybe<Scalars['Float']>
  description?: InputMaybe<Scalars['String']>
  estimate_id?: InputMaybe<Scalars['Int']>
  manufacturer?: InputMaybe<Scalars['String']>
  model?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  quantity?: InputMaybe<Scalars['Int']>
  sales_price?: InputMaybe<Scalars['Float']>
  tag?: InputMaybe<Scalars['String']>
  type?: InputMaybe<AssemblyType>
  visual_id?: InputMaybe<Scalars['String']>
}

export type AssemblyItem = {
  __typename?: 'AssemblyItem'
  active?: Maybe<Scalars['Boolean']>
  assembly_id?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Float']>
  estimate_hour_rate?: Maybe<Scalars['Float']>
  estimate_hours?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  item_manufacturer?: Maybe<Scalars['String']>
  item_manufacturer_part_number?: Maybe<Scalars['String']>
  kit_category_id?: Maybe<Scalars['String']>
  kit_id?: Maybe<Scalars['String']>
  kit_items?: Maybe<Array<Maybe<KitItem>>>
  name?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  sales_price?: Maybe<Scalars['Float']>
  type?: Maybe<AssemblyItemType>
}

export type AssemblyItemInput = {
  active?: InputMaybe<Scalars['Boolean']>
  assembly_id?: InputMaybe<Scalars['String']>
  cost?: InputMaybe<Scalars['Float']>
  deleted?: InputMaybe<Scalars['Boolean']>
  estimate_hour_rate?: InputMaybe<Scalars['Float']>
  estimate_hours?: InputMaybe<Scalars['Float']>
  id?: InputMaybe<Scalars['String']>
  item_id?: InputMaybe<Scalars['String']>
  item_manufacturer?: InputMaybe<Scalars['String']>
  item_manufacturer_part_number?: InputMaybe<Scalars['String']>
  kit_category_id?: InputMaybe<Scalars['String']>
  kit_id?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
  sales_price?: InputMaybe<Scalars['Float']>
  type?: InputMaybe<AssemblyItemType>
}

export enum AssemblyItemType {
  Item = 'item',
  Kit = 'kit'
}

export type AssemblyQuery = {
  search?: InputMaybe<Scalars['String']>
  showInactives?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export enum AssemblyType {
  Estimate = 'estimate',
  Sales = 'sales',
  Sample = 'sample'
}

export type AssemblyUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>
  adjusted_cost?: InputMaybe<Scalars['Float']>
  assembly_items?: InputMaybe<Array<InputMaybe<AssemblyItemInput>>>
  cost?: InputMaybe<Scalars['Float']>
  description?: InputMaybe<Scalars['String']>
  estimate_id?: InputMaybe<Scalars['Int']>
  manufacturer?: InputMaybe<Scalars['String']>
  model?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
  sales_price?: InputMaybe<Scalars['Float']>
  tag?: InputMaybe<Scalars['String']>
  type?: InputMaybe<AssemblyType>
  visual_id?: InputMaybe<Scalars['String']>
}

export enum AuthenticationState {
  Authenticated = 'authenticated',
  Pending = 'pending',
  Unauthenticated = 'unauthenticated'
}

export type CAccessTokenResponse = {
  __typename?: 'CAccessTokenResponse'
  token: Scalars['String']
  user: Contact
}

export type CSignInResponse = {
  __typename?: 'CSignInResponse'
  refreshToken: Scalars['String']
  token: Scalars['String']
  user: Contact
}

export type CategoryKit = {
  __typename?: 'CategoryKit'
  active?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
}

export type CategoryKitInput = {
  active?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  order?: InputMaybe<Scalars['Int']>
}

export type CategoryKitItem = {
  __typename?: 'CategoryKitItem'
  active?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
}

export type CategoryKitItemInput = {
  active?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type CategoryKitQuery = {
  search?: InputMaybe<Scalars['String']>
  showInactives?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type CategoryKitUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Scalars['Int']>
}

export type Company = {
  __typename?: 'Company'
  default_cost_margin?: Maybe<Scalars['Float']>
  default_hour_wage?: Maybe<Scalars['Float']>
  default_inv_asset_account?: Maybe<Scalars['String']>
  default_inv_expense_account?: Maybe<Scalars['String']>
  default_inv_income_account?: Maybe<Scalars['String']>
  default_labor_margin?: Maybe<Scalars['Float']>
  default_non_inv_expense_account?: Maybe<Scalars['String']>
  default_non_inv_income_account?: Maybe<Scalars['String']>
  default_payment_account?: Maybe<Scalars['String']>
  default_payment_method?: Maybe<Scalars['String']>
  default_service_expense_account?: Maybe<Scalars['String']>
  default_service_income_account?: Maybe<Scalars['String']>
  default_tax_code?: Maybe<Scalars['String']>
  default_term_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  quickbooks_connection_info?: Maybe<QuickBooksConnectionInfo>
  quickbooks_id?: Maybe<Scalars['String']>
}

export type Contact = {
  __typename?: 'Contact'
  active?: Maybe<Scalars['Boolean']>
  customer?: Maybe<Customer>
  customer_id?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  id: Scalars['ID']
  last_name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['PhoneNumber']>
  title?: Maybe<Scalars['String']>
  vendor?: Maybe<Vendor>
  vendor_id?: Maybe<Scalars['String']>
}

export type ContactCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>
  customer_id?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  first_name?: InputMaybe<Scalars['String']>
  last_name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['PhoneNumber']>
  title?: InputMaybe<Scalars['String']>
  vendor_id?: InputMaybe<Scalars['String']>
}

export type ContactFilter = {
  active?: InputMaybe<Scalars['Boolean']>
  customer_id: Scalars['ID']
  skip?: InputMaybe<Scalars['Int']>
  take: Scalars['Int']
  title?: InputMaybe<Scalars['String']>
}

export type ContactUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>
  customer_id?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  first_name?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  last_name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['PhoneNumber']>
  title?: InputMaybe<Scalars['String']>
  vendor_id?: InputMaybe<Scalars['String']>
}

export type CreateCustomerSiteInput = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  customer_id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  site_id?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type CreateFolderInput = {
  name?: InputMaybe<Scalars['String']>
  permissions?: InputMaybe<Array<InputMaybe<Permissions>>>
}

export type CreateJobEquipmentInput = {
  equipment?: InputMaybe<EquipmentInput>
  equipment_id?: InputMaybe<Scalars['String']>
  job_id: Scalars['String']
}

export type CreatePayrateInput = {
  end_date?: InputMaybe<Scalars['Date']>
  hours_per_week?: InputMaybe<Scalars['Float']>
  paid_for_overtime?: InputMaybe<Scalars['Boolean']>
  rate: Scalars['Float']
  rate_type: RateType
  start_date: Scalars['Date']
  user_id: Scalars['ID']
}

export type CreateProjectFolderInput = {
  job_id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  permissions?: InputMaybe<Array<InputMaybe<Permissions>>>
}

export type Customer = {
  __typename?: 'Customer'
  active?: Maybe<Scalars['Boolean']>
  billing_address_line1?: Maybe<Scalars['String']>
  billing_address_line2?: Maybe<Scalars['String']>
  billing_city?: Maybe<Scalars['String']>
  billing_country?: Maybe<Scalars['String']>
  billing_postal_code?: Maybe<Scalars['String']>
  billing_state?: Maybe<Scalars['String']>
  company_name?: Maybe<Scalars['String']>
  customer_sites?: Maybe<Array<CustomerSite>>
  default_tax_code?: Maybe<Scalars['String']>
  default_terms_id?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  fully_qualified_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  job?: Maybe<Scalars['Boolean']>
  last_name?: Maybe<Scalars['String']>
  parent_customer?: Maybe<Customer>
  parent_customer_qbid?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['PhoneNumber']>
  quickbooks_id?: Maybe<Scalars['String']>
  taxable?: Maybe<Scalars['Boolean']>
}

export type CustomerRequest = {
  __typename?: 'CustomerRequest'
  additional_site_information?: Maybe<Scalars['String']>
  contact?: Maybe<Contact>
  contact_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['Date']>
  customer?: Maybe<Customer>
  customer_id?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  issue?: Maybe<IssueEnum>
  issue_details?: Maybe<Scalars['String']>
  job?: Maybe<Job>
  job_id?: Maybe<Scalars['String']>
  requested_by?: Maybe<Contact>
  requested_by_id?: Maybe<Scalars['String']>
  scheduling_information?: Maybe<Scalars['String']>
  site?: Maybe<Site>
  site_id?: Maybe<Scalars['String']>
}

export type CustomerRequestFilter = {
  customer_id?: InputMaybe<Scalars['ID']>
  jobFilter?: InputMaybe<Scalars['Boolean']>
}

export type CustomerRequestInput = {
  filter?: InputMaybe<CustomerRequestFilter>
  skip?: InputMaybe<Scalars['Int']>
  take: Scalars['Int']
}

export type CustomerSite = {
  __typename?: 'CustomerSite'
  customer?: Maybe<Customer>
  customer_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  jobs?: Maybe<Array<Maybe<Job>>>
  site?: Maybe<Site>
  site_id?: Maybe<Scalars['String']>
}

export type CustomersQuery = {
  project?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  showInactives?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type Employee = {
  __typename?: 'Employee'
  active?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  last_name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['PhoneNumber']>
  quickbooks_id?: Maybe<Scalars['String']>
}

export type EmployeesQuery = {
  search?: InputMaybe<Scalars['String']>
}

export type EmployeesQueryResults = {
  __typename?: 'EmployeesQueryResults'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Employee>>
}

export type Equipment = {
  __typename?: 'Equipment'
  area_servicing?: Maybe<Scalars['String']>
  brand?: Maybe<Scalars['String']>
  custom_fields?: Maybe<Scalars['JSONObject']>
  equipment_type?: Maybe<EquipmentType>
  equipment_type_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  job_id?: Maybe<Scalars['String']>
  modal?: Maybe<Scalars['String']>
  physical_location?: Maybe<Scalars['String']>
  serial_number?: Maybe<Scalars['String']>
  site_id?: Maybe<Scalars['String']>
  warranty_link?: Maybe<Scalars['String']>
}

export type EquipmentInput = {
  area_servicing?: InputMaybe<Scalars['String']>
  brand: Scalars['String']
  custom_fields?: InputMaybe<Scalars['JSONObject']>
  equipment_type_id?: InputMaybe<Scalars['String']>
  modal: Scalars['String']
  physical_location?: InputMaybe<Scalars['String']>
  serial_number: Scalars['String']
  warranty_link?: InputMaybe<Scalars['String']>
}

export type EquipmentQuery = {
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take: Scalars['Int']
}

export type EquipmentType = {
  __typename?: 'EquipmentType'
  equipment_type_fields?: Maybe<Array<Maybe<EquipmentTypeField>>>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type EquipmentTypeField = {
  __typename?: 'EquipmentTypeField'
  field_name?: Maybe<Scalars['String']>
  field_type?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
}

export type EquipmentUpdateInput = {
  area_servicing?: InputMaybe<Scalars['String']>
  brand?: InputMaybe<Scalars['String']>
  custom_fields?: InputMaybe<Scalars['JSONObject']>
  equipment_type_id?: InputMaybe<Scalars['String']>
  modal?: InputMaybe<Scalars['String']>
  physical_location?: InputMaybe<Scalars['String']>
  serial_number?: InputMaybe<Scalars['String']>
  site_id?: InputMaybe<Scalars['String']>
  warranty_link?: InputMaybe<Scalars['String']>
}

export type EquipmentsInput = {
  filter_job_id?: InputMaybe<Scalars['String']>
  job_id?: InputMaybe<Scalars['String']>
  searchEquipments?: InputMaybe<Scalars['String']>
  site_id?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type EquipmentsPaginated = {
  __typename?: 'EquipmentsPaginated'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<Equipment>>>
}

export type EquipmnetTypeInput = {
  custom_fields?: InputMaybe<Scalars['JSONObject']>
  name: Scalars['String']
}

export type Estimate = {
  __typename?: 'Estimate'
  address?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Float']>
  created_at?: Maybe<Scalars['Date']>
  customer_id?: Maybe<Scalars['String']>
  customer_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  estimate_lines?: Maybe<Array<Maybe<EstimateLine>>>
  id?: Maybe<Scalars['ID']>
  job_name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  sales_price?: Maybe<Scalars['Float']>
  state?: Maybe<Scalars['String']>
  visual_id?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type EstimateInput = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  cost?: InputMaybe<Scalars['Float']>
  customer_id?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  estimate_lines?: InputMaybe<Array<InputMaybe<EstimateLineInput>>>
  job_name: Scalars['String']
  phone?: InputMaybe<Scalars['String']>
  sales_price?: InputMaybe<Scalars['Float']>
  state?: InputMaybe<Scalars['String']>
  visual_id?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type EstimateLine = {
  __typename?: 'EstimateLine'
  active?: Maybe<Scalars['Boolean']>
  adjusted_cost?: Maybe<Scalars['Float']>
  assembly_id?: Maybe<Scalars['String']>
  assembly_items?: Maybe<Array<Maybe<AssemblyItem>>>
  cost?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  estimate_hour_rate?: Maybe<Scalars['Float']>
  estimate_hours?: Maybe<Scalars['Float']>
  estimate_id?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  item_manufacturer?: Maybe<Scalars['String']>
  item_manufacturer_part_number?: Maybe<Scalars['String']>
  kit_id?: Maybe<Scalars['String']>
  kit_items?: Maybe<Array<Maybe<KitItem>>>
  manufacturer?: Maybe<Scalars['String']>
  model?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  sales_price?: Maybe<Scalars['Float']>
  tag?: Maybe<Scalars['String']>
  type?: Maybe<EstimateLineType>
}

export type EstimateLineInput = {
  active?: InputMaybe<Scalars['Boolean']>
  adjusted_cost?: InputMaybe<Scalars['Float']>
  assembly_id?: InputMaybe<Scalars['String']>
  cost?: InputMaybe<Scalars['Float']>
  deleted?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  estimate_hour_rate?: InputMaybe<Scalars['Float']>
  estimate_hours?: InputMaybe<Scalars['Float']>
  estimate_id?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['String']>
  item_id?: InputMaybe<Scalars['String']>
  item_manufacturer?: InputMaybe<Scalars['String']>
  item_manufacturer_part_number?: InputMaybe<Scalars['String']>
  kit_id?: InputMaybe<Scalars['String']>
  manufacturer?: InputMaybe<Scalars['String']>
  model?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
  sales_price?: InputMaybe<Scalars['Float']>
  tag?: InputMaybe<Scalars['String']>
  type?: InputMaybe<EstimateLineType>
}

export enum EstimateLineType {
  Assembly = 'assembly',
  Item = 'item',
  Kit = 'kit'
}

export type EstimateQuery = {
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type EstimateUpdateInput = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  cost?: InputMaybe<Scalars['Float']>
  customer_id?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  estimate_lines?: InputMaybe<Array<InputMaybe<EstimateLineInput>>>
  job_name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  sales_price?: InputMaybe<Scalars['Float']>
  state?: InputMaybe<Scalars['String']>
  visual_id?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type FileSettings = {
  __typename?: 'FileSettings'
  id: Scalars['ID']
  name: Scalars['String']
  permissions?: Maybe<Array<Maybe<Permissions>>>
}

export type FinancialTotals = {
  __typename?: 'FinancialTotals'
  total_cost?: Maybe<Scalars['Float']>
  total_hours?: Maybe<Scalars['Float']>
  total_overtime_hours?: Maybe<Scalars['Float']>
  total_regular_hours?: Maybe<Scalars['Float']>
}

export type Folders = {
  __typename?: 'Folders'
  created_by?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  job_id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  permissions?: Maybe<Array<Maybe<Permissions>>>
  project_files?: Maybe<Array<Maybe<ProjectFile>>>
}

export type GenerateFileInput = {
  folder?: InputMaybe<Scalars['String']>
  job_id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
}

export type GlobalSearchResults = {
  __typename?: 'GlobalSearchResults'
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  last_name?: Maybe<Scalars['String']>
  other?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type IntegrationLog = {
  __typename?: 'IntegrationLog'
  created_at?: Maybe<Scalars['Date']>
  ended_at: Scalars['Date']
  id?: Maybe<Scalars['ID']>
  logs?: Maybe<Scalars['String']>
  started_at: Scalars['Date']
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type IntegrationLogFilter = {
  endDate?: InputMaybe<Scalars['Date']>
  skip?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['Date']>
  status?: InputMaybe<Scalars['String']>
  take?: InputMaybe<Scalars['Int']>
}

export type InventoryItemInput = {
  active?: InputMaybe<Scalars['Boolean']>
  asset_account?: InputMaybe<Scalars['String']>
  cost?: InputMaybe<Scalars['Float']>
  description?: InputMaybe<Scalars['String']>
  estimate_hour_rate?: InputMaybe<Scalars['Float']>
  estimate_hours?: InputMaybe<Scalars['Float']>
  expense_account?: InputMaybe<Scalars['String']>
  income_account?: InputMaybe<Scalars['String']>
  item_manufacturer?: InputMaybe<Scalars['String']>
  item_manufacturer_part_number?: InputMaybe<Scalars['String']>
  item_vendors?: InputMaybe<Array<InputMaybe<ItemVendorsInput>>>
  name?: InputMaybe<Scalars['String']>
  product_category?: InputMaybe<Scalars['Int']>
  qty_on_hand?: InputMaybe<Scalars['Int']>
  sku?: InputMaybe<Scalars['String']>
  taxable?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<Scalars['String']>
  unit_price?: InputMaybe<Scalars['Float']>
}

export type InventoryItemUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>
  asset_account?: InputMaybe<Scalars['String']>
  cost?: InputMaybe<Scalars['Float']>
  description?: InputMaybe<Scalars['String']>
  estimate_hour_rate?: InputMaybe<Scalars['Float']>
  estimate_hours?: InputMaybe<Scalars['Float']>
  expense_account?: InputMaybe<Scalars['String']>
  income_account?: InputMaybe<Scalars['String']>
  item_manufacturer?: InputMaybe<Scalars['String']>
  item_manufacturer_part_number?: InputMaybe<Scalars['String']>
  item_vendors?: InputMaybe<Array<InputMaybe<ItemVendorsInput>>>
  name?: InputMaybe<Scalars['String']>
  product_category?: InputMaybe<Scalars['Int']>
  qty_on_hand?: InputMaybe<Scalars['Int']>
  sku?: InputMaybe<Scalars['String']>
  taxable?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<Scalars['String']>
  unit_price?: InputMaybe<Scalars['Float']>
}

export type InventoryItemsQuery = {
  search?: InputMaybe<Scalars['String']>
  showInactives?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type Invoice = {
  __typename?: 'Invoice'
  address_line_five?: Maybe<Scalars['String']>
  address_line_four?: Maybe<Scalars['String']>
  address_line_one?: Maybe<Scalars['String']>
  address_line_three?: Maybe<Scalars['String']>
  address_line_two?: Maybe<Scalars['String']>
  balance?: Maybe<Scalars['Float']>
  city?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['Date']>
  created_date?: Maybe<Scalars['Date']>
  custom_field?: Maybe<Scalars['String']>
  customer?: Maybe<Customer>
  due_date?: Maybe<Scalars['Date']>
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  invoice_lines?: Maybe<Array<Maybe<InvoiceLines>>>
  invoice_number?: Maybe<Scalars['Int']>
  last_updated_date?: Maybe<Scalars['Date']>
  memo?: Maybe<Scalars['String']>
  payments?: Maybe<Array<Maybe<Payments>>>
  private_note?: Maybe<Scalars['String']>
  quickbooks_id?: Maybe<Scalars['String']>
  reference_id?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  tax_code_id?: Maybe<Scalars['String']>
  term_id?: Maybe<Scalars['String']>
  total_amount?: Maybe<Scalars['Float']>
  total_tax?: Maybe<Scalars['Float']>
  transaction_date?: Maybe<Scalars['Date']>
  zip?: Maybe<Scalars['String']>
}

export type InvoiceInput = {
  address_line_five?: InputMaybe<Scalars['String']>
  address_line_four?: InputMaybe<Scalars['String']>
  address_line_one?: InputMaybe<Scalars['String']>
  address_line_three?: InputMaybe<Scalars['String']>
  address_line_two?: InputMaybe<Scalars['String']>
  balance?: InputMaybe<Scalars['Float']>
  city?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['Date']>
  created_date?: InputMaybe<Scalars['Date']>
  custom_field?: InputMaybe<Scalars['String']>
  due_date?: InputMaybe<Scalars['Date']>
  email?: InputMaybe<Scalars['String']>
  invoice_lines?: InputMaybe<Array<InputMaybe<InvoiceLineInput>>>
  invoice_number?: InputMaybe<Scalars['Int']>
  last_updated_date?: InputMaybe<Scalars['Date']>
  memo?: InputMaybe<Scalars['String']>
  private_note?: InputMaybe<Scalars['String']>
  quickbooks_id?: InputMaybe<Scalars['String']>
  reference_id?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  tax_code_id?: InputMaybe<Scalars['String']>
  term_id?: InputMaybe<Scalars['String']>
  total_amount?: InputMaybe<Scalars['Float']>
  total_tax?: InputMaybe<Scalars['Float']>
  transaction_date?: InputMaybe<Scalars['Date']>
  zip?: InputMaybe<Scalars['String']>
}

export type InvoiceLineInput = {
  amount?: InputMaybe<Scalars['Float']>
  date?: InputMaybe<Scalars['Date']>
  description?: InputMaybe<Scalars['String']>
  discount_account_id?: InputMaybe<Scalars['String']>
  discount_percent?: InputMaybe<Scalars['Float']>
  id?: InputMaybe<Scalars['String']>
  invoice_id?: InputMaybe<Scalars['String']>
  is_taxable?: InputMaybe<Scalars['Boolean']>
  item_id?: InputMaybe<Scalars['String']>
  line_num?: InputMaybe<Scalars['String']>
  qty?: InputMaybe<Scalars['Float']>
  rate?: InputMaybe<Scalars['Float']>
  type?: InputMaybe<InvoiceTypeEnum>
}

export type InvoiceLines = {
  __typename?: 'InvoiceLines'
  amount?: Maybe<Scalars['Float']>
  date?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  discount_account_id?: Maybe<Scalars['String']>
  discount_percent?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  invoice_id?: Maybe<Scalars['String']>
  is_taxable?: Maybe<Scalars['Boolean']>
  item_id?: Maybe<Scalars['String']>
  qty?: Maybe<Scalars['Float']>
  rate?: Maybe<Scalars['Float']>
  type?: Maybe<InvoiceTypeEnum>
}

export type InvoiceQuery = {
  customer_id?: InputMaybe<Scalars['String']>
  invoiceArray?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  noBalance?: InputMaybe<Scalars['Boolean']>
  qb_job_id?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export enum InvoiceTypeEnum {
  Description = 'description',
  Discount = 'discount',
  SalesItem = 'sales_item',
  SubTotal = 'sub_total'
}

export type InvoiceUpdateInput = {
  address_line_five?: InputMaybe<Scalars['String']>
  address_line_four?: InputMaybe<Scalars['String']>
  address_line_one?: InputMaybe<Scalars['String']>
  address_line_three?: InputMaybe<Scalars['String']>
  address_line_two?: InputMaybe<Scalars['String']>
  balance?: InputMaybe<Scalars['Float']>
  city?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['Date']>
  created_date?: InputMaybe<Scalars['Date']>
  custom_field?: InputMaybe<Scalars['String']>
  due_date?: InputMaybe<Scalars['Date']>
  email?: InputMaybe<Scalars['String']>
  invoice_lines?: InputMaybe<Array<InputMaybe<InvoiceLineInput>>>
  invoice_number?: InputMaybe<Scalars['Int']>
  last_updated_date?: InputMaybe<Scalars['Date']>
  memo?: InputMaybe<Scalars['String']>
  private_note?: InputMaybe<Scalars['String']>
  quickbooks_id?: InputMaybe<Scalars['String']>
  reference_id?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  tax_code_id?: InputMaybe<Scalars['String']>
  term_id?: InputMaybe<Scalars['String']>
  total_amount?: InputMaybe<Scalars['Float']>
  total_tax?: InputMaybe<Scalars['Float']>
  transaction_date?: InputMaybe<Scalars['Date']>
  zip?: InputMaybe<Scalars['String']>
}

export enum IssueEnum {
  Cooling = 'cooling',
  Heating = 'heating',
  Other = 'other'
}

export type Item = {
  __typename?: 'Item'
  active?: Maybe<Scalars['Boolean']>
  asset_account?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  estimate_hour_rate?: Maybe<Scalars['Float']>
  estimate_hours?: Maybe<Scalars['Float']>
  expense_account?: Maybe<Scalars['String']>
  id: Scalars['ID']
  income_account?: Maybe<Scalars['String']>
  item_manufacturer?: Maybe<Scalars['String']>
  item_manufacturer_part_number?: Maybe<Scalars['String']>
  item_vendors?: Maybe<Array<Maybe<ItemVendors>>>
  name?: Maybe<Scalars['String']>
  product_category?: Maybe<Scalars['Int']>
  qty_on_hand?: Maybe<Scalars['Int']>
  sku?: Maybe<Scalars['String']>
  taxable?: Maybe<Scalars['Boolean']>
  type?: Maybe<Scalars['String']>
  unit_price?: Maybe<Scalars['Float']>
}

export type ItemQuery = {
  search?: InputMaybe<Scalars['String']>
  showInactives?: InputMaybe<Scalars['Boolean']>
}

export enum ItemType {
  Inventory = 'Inventory',
  NonInventory = 'NonInventory',
  Service = 'Service'
}

export type ItemVendors = {
  __typename?: 'ItemVendors'
  amount?: Maybe<Scalars['Float']>
  code?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type ItemVendorsInput = {
  amount?: InputMaybe<Scalars['Float']>
  code?: InputMaybe<Scalars['String']>
  deleted?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  item_id?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type Job = {
  __typename?: 'Job'
  active?: Maybe<Scalars['Boolean']>
  additional_information?: Maybe<Scalars['String']>
  customer_request?: Maybe<CustomerRequest>
  customer_site?: Maybe<CustomerSite>
  description?: Maybe<Scalars['String']>
  files?: Maybe<Array<Maybe<Scalars['String']>>>
  id: Scalars['ID']
  issue?: Maybe<IssueEnum>
  issue_details?: Maybe<Scalars['String']>
  job_schedules?: Maybe<Array<JobSchedule>>
  label_id?: Maybe<Scalars['Int']>
  name: Scalars['String']
  next_schedule_date?: Maybe<Scalars['Date']>
  status?: Maybe<JobStatus>
  time_entries?: Maybe<Array<TimeEntry>>
  transactions?: Maybe<Array<Transaction>>
  type: JobTypes
}

export type JobCreateInput = {
  additional_information?: InputMaybe<Scalars['String']>
  customer_site_id?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  issue?: InputMaybe<IssueEnum>
  issue_details?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  status?: InputMaybe<JobStatus>
  type: JobTypes
}

export type JobEquipment = {
  __typename?: 'JobEquipment'
  equipment?: Maybe<Equipment>
  equipment_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  job?: Maybe<Job>
  job_id?: Maybe<Scalars['String']>
}

export type JobEquipmentHistoryInput = {
  equipment_ids: Array<InputMaybe<Scalars['ID']>>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type JobFinancialTotal = {
  __typename?: 'JobFinancialTotal'
  job_id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  total_cost?: Maybe<Scalars['Float']>
  total_overtime_hours?: Maybe<Scalars['Float']>
  total_regular_hours?: Maybe<Scalars['Float']>
}

export type JobFinancialTotalInput = {
  end_date: Scalars['Date']
  job_id?: InputMaybe<Scalars['ID']>
  skip?: InputMaybe<Scalars['Int']>
  start_date: Scalars['Date']
  take: Scalars['Int']
  user_id?: InputMaybe<Scalars['ID']>
}

export type JobFinancialTotals = {
  __typename?: 'JobFinancialTotals'
  job_id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  total_cost?: Maybe<Scalars['Float']>
  total_overtime_hours?: Maybe<Scalars['Float']>
  total_regular_hours?: Maybe<Scalars['Float']>
}

export type JobSchedule = {
  __typename?: 'JobSchedule'
  end_time?: Maybe<Scalars['Date']>
  id?: Maybe<Scalars['ID']>
  job?: Maybe<Job>
  job_id?: Maybe<Scalars['ID']>
  start_time?: Maybe<Scalars['Date']>
  tech_job_schedule?: Maybe<Array<Maybe<TechJobSchedule>>>
}

export type JobScheduleInput = {
  end_time?: InputMaybe<Scalars['Date']>
  job_id: Scalars['ID']
  start_time?: InputMaybe<Scalars['Date']>
  tech_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
}

export type JobSchedulesInput = {
  end_time: Scalars['Date']
  job_type?: InputMaybe<JobTypes>
  site_id?: InputMaybe<Scalars['ID']>
  start_time: Scalars['Date']
  user_id?: InputMaybe<Scalars['ID']>
}

export enum JobStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
  OnHold = 'on_hold',
  Open = 'open'
}

export enum JobTypes {
  Estimate = 'estimate',
  Project = 'project',
  ServiceCall = 'service_call'
}

export type JobTypesResult = {
  __typename?: 'JobTypesResult'
  results?: Maybe<Array<JobTypes>>
}

export type JobUpdateInput = {
  additional_information?: InputMaybe<Scalars['String']>
  customer_site_id?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  issue?: InputMaybe<IssueEnum>
  issue_details?: InputMaybe<Scalars['String']>
  job_equipments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<JobStatus>
  type?: InputMaybe<JobTypes>
}

export type JobUpdateStatusInput = {
  status?: InputMaybe<JobStatus>
}

export type JobsQuery = {
  filter?: InputMaybe<SearchFilter>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type Kit = {
  __typename?: 'Kit'
  active?: Maybe<Scalars['Boolean']>
  adjusted_cost?: Maybe<Scalars['Float']>
  cost?: Maybe<Scalars['Float']>
  cost_margin?: Maybe<Scalars['Float']>
  estimate_id?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
  kit_category_id?: Maybe<Scalars['String']>
  kit_items?: Maybe<Array<Maybe<KitItem>>>
  labor_margin?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  sales_price?: Maybe<Scalars['Float']>
  type?: Maybe<KitType>
  visual_id?: Maybe<Scalars['String']>
}

export type KitInput = {
  active?: InputMaybe<Scalars['Boolean']>
  adjusted_cost?: InputMaybe<Scalars['Float']>
  cost?: InputMaybe<Scalars['Float']>
  cost_margin?: InputMaybe<Scalars['Float']>
  estimate_id?: InputMaybe<Scalars['Int']>
  kit_category_id?: InputMaybe<Scalars['String']>
  kit_items?: InputMaybe<Array<InputMaybe<KitItemInput>>>
  labor_margin?: InputMaybe<Scalars['Float']>
  name: Scalars['String']
  quantity?: InputMaybe<Scalars['Int']>
  sales_price?: InputMaybe<Scalars['Float']>
  type?: InputMaybe<KitType>
  visual_id?: InputMaybe<Scalars['String']>
}

export type KitItem = {
  __typename?: 'KitItem'
  active?: Maybe<Scalars['Boolean']>
  cost?: Maybe<Scalars['Float']>
  estimate_hour_rate?: Maybe<Scalars['Float']>
  estimate_hours?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  item_manufacturer?: Maybe<Scalars['String']>
  item_manufacturer_part_number?: Maybe<Scalars['String']>
  kit_id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
}

export type KitItemInput = {
  active?: InputMaybe<Scalars['Boolean']>
  cost?: InputMaybe<Scalars['Float']>
  deleted?: InputMaybe<Scalars['Boolean']>
  estimate_hour_rate?: InputMaybe<Scalars['Float']>
  estimate_hours?: InputMaybe<Scalars['Float']>
  id?: InputMaybe<Scalars['String']>
  item_id?: InputMaybe<Scalars['String']>
  item_manufacturer?: InputMaybe<Scalars['String']>
  item_manufacturer_part_number?: InputMaybe<Scalars['String']>
  kit_id?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
}

export type KitQuery = {
  kit_category_id?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  showInactives?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<KitType>
}

export enum KitType {
  Estimate = 'estimate',
  Sales = 'sales',
  Sample = 'sample'
}

export type KitUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>
  adjusted_cost?: InputMaybe<Scalars['Float']>
  cost?: InputMaybe<Scalars['Float']>
  cost_margin?: InputMaybe<Scalars['Float']>
  estimate_id?: InputMaybe<Scalars['Int']>
  kit_category_id?: InputMaybe<Scalars['String']>
  kit_items?: InputMaybe<Array<InputMaybe<KitItemInput>>>
  labor_margin?: InputMaybe<Scalars['Float']>
  name?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
  sales_price?: InputMaybe<Scalars['Float']>
  type?: InputMaybe<KitType>
  visual_id?: InputMaybe<Scalars['String']>
}

export type Material = {
  __typename?: 'Material'
  id: Scalars['ID']
  name: Scalars['String']
  transactions?: Maybe<Array<Maybe<TransactionMaterial>>>
  vendor: Vendor
}

export type MaterialCreateInput = {
  name: Scalars['String']
}

export type MaterialInput = {
  material_id?: InputMaybe<Scalars['ID']>
  quantity?: InputMaybe<Scalars['Int']>
  total_cost?: InputMaybe<Scalars['Float']>
}

export type MaterialsQuery = {
  skip?: InputMaybe<Scalars['Int']>
  take: Scalars['Int']
}

export type MaterialsVendorQuery = {
  searchMaterial?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take: Scalars['Int']
  vendorId?: InputMaybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  addTechToSchedule?: Maybe<TechJobSchedule>
  authenticateAndSyncQbTokens: QuickBooksAuthenticationState
  cCreateCustomerRequest?: Maybe<CustomerRequest>
  cCreateSite?: Maybe<Site>
  cForgotPassword?: Maybe<Scalars['Boolean']>
  cGetAccessToken: CAccessTokenResponse
  cIsResetPasswordValid?: Maybe<Scalars['Boolean']>
  cLogout: Scalars['Boolean']
  cResetPassword?: Maybe<Scalars['Boolean']>
  cSignIn: CSignInResponse
  cSignUp?: Maybe<Scalars['Boolean']>
  cUpdateCustomerRequest?: Maybe<CustomerRequest>
  cUpdateProfile?: Maybe<Contact>
  clockIn?: Maybe<TimeEntry>
  clockOut?: Maybe<TimeEntry>
  createAssembly?: Maybe<Assembly>
  createCategoryKit?: Maybe<CategoryKit>
  createContact?: Maybe<Contact>
  createCustomerSite?: Maybe<CustomerSite>
  createEquipmentType?: Maybe<EquipmentType>
  createEstimate?: Maybe<Estimate>
  createFolder?: Maybe<Scalars['Boolean']>
  createInventoryItem?: Maybe<Item>
  createInvoice?: Maybe<Invoice>
  createJob?: Maybe<Job>
  createJobEquipment?: Maybe<JobEquipment>
  createJobSchedule?: Maybe<JobSchedule>
  createKit?: Maybe<Kit>
  createMaterial?: Maybe<Material>
  createPayment?: Maybe<Payment>
  createPayrate?: Maybe<Payrate>
  createProjectFile?: Maybe<Scalars['Boolean']>
  createProjectFolder?: Maybe<Scalars['Boolean']>
  createQuote?: Maybe<Quote>
  createRole?: Maybe<Role>
  createRoute?: Maybe<Route>
  createSite?: Maybe<Site>
  createTimeEntry?: Maybe<TimeEntry>
  createTimeEntryRequest?: Maybe<TimeEntryRequest>
  createTransaction?: Maybe<Transaction>
  createTransactionMaterial?: Maybe<TransactionMaterial>
  createTransactionReceipt?: Maybe<TransactionReceipt>
  createUser: User
  deleteAssemblyLine?: Maybe<Scalars['Int']>
  deleteContact?: Maybe<Scalars['Int']>
  deleteEstimateAssemblyLine?: Maybe<Scalars['Int']>
  deleteEstimateLine?: Maybe<Scalars['Int']>
  deleteFolder?: Maybe<Scalars['Int']>
  deleteJobSchedule?: Maybe<Scalars['Int']>
  deleteKitLine?: Maybe<Scalars['Int']>
  deletePayrate?: Maybe<Scalars['Int']>
  deleteProjectFile?: Maybe<Scalars['Boolean']>
  deleteProjectFolder?: Maybe<Scalars['Boolean']>
  deleteQuote?: Maybe<Scalars['Int']>
  deleteTimeEntry?: Maybe<Scalars['Int']>
  deleteTransaction?: Maybe<Scalars['Int']>
  deleteTransactionLine?: Maybe<Scalars['Int']>
  deleteTransactionReceipt?: Maybe<Scalars['Int']>
  deleteVendorLine?: Maybe<Scalars['Int']>
  deleteWarrantyLink?: Maybe<Scalars['Boolean']>
  disconnectQb?: Maybe<QuickBooksAuthenticationState>
  forgotPassword?: Maybe<Scalars['Boolean']>
  generateFileLink?: Maybe<UploadLink>
  generateReceiptUploadLink?: Maybe<UploadLink>
  generateWarrantyLink?: Maybe<UploadLink>
  getAccessToken: AccessTokenResponse
  isResetPasswordValid?: Maybe<Scalars['Boolean']>
  logout: Scalars['Boolean']
  refreshQBTokens: QuickBooksAuthenticationState
  removeTechFromSchedule?: Maybe<Scalars['Int']>
  resetPassword?: Maybe<Scalars['Boolean']>
  signIn: SignInResponse
  syncTimeEntries?: Maybe<Scalars['Boolean']>
  timeEntryRequestStatus?: Maybe<TimeEntryRequest>
  updateAssembly?: Maybe<Assembly>
  updateCategoryKit?: Maybe<CategoryKit>
  updateCompany?: Maybe<Company>
  updateContact?: Maybe<Contact>
  updateCustomerActive?: Maybe<Customer>
  updateEquipment?: Maybe<Equipment>
  updateEstimate?: Maybe<Estimate>
  updateFileName?: Maybe<Scalars['Boolean']>
  updateFolder?: Maybe<Scalars['Boolean']>
  updateInventoryItem?: Maybe<Item>
  updateInvoice?: Maybe<Invoice>
  updateJob?: Maybe<Job>
  updateJobActive?: Maybe<Scalars['Int']>
  updateJobSchedule?: Maybe<JobSchedule>
  updateJobStatus?: Maybe<Job>
  updateKit?: Maybe<Kit>
  updatePayment?: Maybe<Payment>
  updatePayrate?: Maybe<Payrate>
  updateQuote?: Maybe<Quote>
  updateRolePermission?: Maybe<Array<Maybe<RolePermission>>>
  updateSite?: Maybe<Site>
  updateTimeEntry?: Maybe<TimeEntry>
  updateTransaction?: Maybe<Transaction>
  updateTransactionSingle?: Maybe<Transaction>
  updateUser: User
  updateUserRole?: Maybe<UserRole>
  updateVendorActive?: Maybe<Vendor>
  viewFileLink?: Maybe<Scalars['String']>
  viewReceiptLink?: Maybe<Scalars['String']>
}

export type MutationAddTechToScheduleArgs = {
  data: AddTechInput
}

export type MutationAuthenticateAndSyncQbTokensArgs = {
  data: QuickBooksTokensInput
}

export type MutationCCreateCustomerRequestArgs = {
  data: CCreateCustomerRequestInput
}

export type MutationCCreateSiteArgs = {
  data: SiteCreateInput
}

export type MutationCForgotPasswordArgs = {
  email: Scalars['String']
}

export type MutationCGetAccessTokenArgs = {
  refreshToken: Scalars['String']
}

export type MutationCIsResetPasswordValidArgs = {
  email: Scalars['String']
  password_reset: Scalars['String']
}

export type MutationCLogoutArgs = {
  refreshToken: Scalars['String']
}

export type MutationCResetPasswordArgs = {
  email: Scalars['String']
  password: Scalars['String']
  password_reset: Scalars['String']
}

export type MutationCSignInArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationCSignUpArgs = {
  email: Scalars['String']
}

export type MutationCUpdateCustomerRequestArgs = {
  data: CUpdateCustomerRequestInput
}

export type MutationCUpdateProfileArgs = {
  data: CProfileUpdateInput
}

export type MutationClockInArgs = {
  job_id: Scalars['String']
  note?: InputMaybe<Scalars['String']>
}

export type MutationClockOutArgs = {
  note?: InputMaybe<Scalars['String']>
}

export type MutationCreateAssemblyArgs = {
  data?: InputMaybe<AssemblyInput>
}

export type MutationCreateCategoryKitArgs = {
  data?: InputMaybe<CategoryKitInput>
}

export type MutationCreateContactArgs = {
  contact: ContactCreateInput
}

export type MutationCreateCustomerSiteArgs = {
  data: CreateCustomerSiteInput
}

export type MutationCreateEquipmentTypeArgs = {
  data: EquipmnetTypeInput
}

export type MutationCreateEstimateArgs = {
  data?: InputMaybe<EstimateInput>
}

export type MutationCreateFolderArgs = {
  folder: CreateFolderInput
}

export type MutationCreateInventoryItemArgs = {
  data?: InputMaybe<InventoryItemInput>
}

export type MutationCreateInvoiceArgs = {
  data?: InputMaybe<InvoiceInput>
}

export type MutationCreateJobArgs = {
  customer_request_id?: InputMaybe<Scalars['ID']>
  data?: InputMaybe<JobCreateInput>
}

export type MutationCreateJobEquipmentArgs = {
  data: CreateJobEquipmentInput
}

export type MutationCreateJobScheduleArgs = {
  data: JobScheduleInput
}

export type MutationCreateKitArgs = {
  data?: InputMaybe<KitInput>
}

export type MutationCreateMaterialArgs = {
  data: MaterialCreateInput
}

export type MutationCreatePaymentArgs = {
  data?: InputMaybe<PaymentInput>
}

export type MutationCreatePayrateArgs = {
  data: CreatePayrateInput
}

export type MutationCreateProjectFileArgs = {
  data: ProjectFileCreateInput
}

export type MutationCreateProjectFolderArgs = {
  folder: CreateProjectFolderInput
}

export type MutationCreateQuoteArgs = {
  data?: InputMaybe<QuoteInput>
}

export type MutationCreateRoleArgs = {
  data?: InputMaybe<RoleCreateInput>
}

export type MutationCreateRouteArgs = {
  data?: InputMaybe<RouteCreateInput>
}

export type MutationCreateSiteArgs = {
  data: SiteCreateInput
}

export type MutationCreateTimeEntryArgs = {
  timeEntry: TimeEntryCreateInput
}

export type MutationCreateTimeEntryRequestArgs = {
  data: RequestCreateInput
}

export type MutationCreateTransactionArgs = {
  data?: InputMaybe<TransactionCreateInput>
}

export type MutationCreateTransactionMaterialArgs = {
  data: TransactionMaterialCreateInput
}

export type MutationCreateTransactionReceiptArgs = {
  data: TransactionReceiptCreateInput
}

export type MutationCreateUserArgs = {
  user?: InputMaybe<UserCreateInput>
}

export type MutationDeleteAssemblyLineArgs = {
  id: Scalars['ID']
}

export type MutationDeleteContactArgs = {
  id: Scalars['ID']
}

export type MutationDeleteEstimateAssemblyLineArgs = {
  id: Scalars['ID']
}

export type MutationDeleteEstimateLineArgs = {
  id: Scalars['ID']
}

export type MutationDeleteFolderArgs = {
  id: Scalars['ID']
}

export type MutationDeleteJobScheduleArgs = {
  job_schedule_id: Scalars['ID']
}

export type MutationDeleteKitLineArgs = {
  id: Scalars['ID']
}

export type MutationDeletePayrateArgs = {
  id: Scalars['ID']
}

export type MutationDeleteProjectFileArgs = {
  data: DeleteProjectFileInput
}

export type MutationDeleteProjectFolderArgs = {
  data: DeleteProjectFolderInput
}

export type MutationDeleteQuoteArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTimeEntryArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTransactionArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTransactionLineArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTransactionReceiptArgs = {
  id: Scalars['ID']
}

export type MutationDeleteVendorLineArgs = {
  id: Scalars['ID']
}

export type MutationDeleteWarrantyLinkArgs = {
  id: Scalars['ID']
}

export type MutationForgotPasswordArgs = {
  email: Scalars['String']
}

export type MutationGenerateFileLinkArgs = {
  data: GenerateFileInput
}

export type MutationGenerateReceiptUploadLinkArgs = {
  transactionId: Scalars['ID']
}

export type MutationGenerateWarrantyLinkArgs = {
  equipment_id: Scalars['String']
  file_name: Scalars['String']
}

export type MutationGetAccessTokenArgs = {
  refreshToken: Scalars['String']
}

export type MutationIsResetPasswordValidArgs = {
  email: Scalars['String']
  password_reset: Scalars['String']
}

export type MutationLogoutArgs = {
  refreshToken: Scalars['String']
}

export type MutationRemoveTechFromScheduleArgs = {
  id: Scalars['ID']
}

export type MutationResetPasswordArgs = {
  email: Scalars['String']
  password: Scalars['String']
  password_reset: Scalars['String']
}

export type MutationSignInArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationSyncTimeEntriesArgs = {
  endDate: Scalars['Date']
  startDate: Scalars['Date']
}

export type MutationTimeEntryRequestStatusArgs = {
  data: TimeEntryStatusInput
}

export type MutationUpdateAssemblyArgs = {
  data: AssemblyUpdateInput
  id: Scalars['ID']
}

export type MutationUpdateCategoryKitArgs = {
  data: CategoryKitUpdateInput
  id: Scalars['ID']
}

export type MutationUpdateCompanyArgs = {
  company: UpdateCompanyInput
}

export type MutationUpdateContactArgs = {
  contact: ContactUpdateInput
}

export type MutationUpdateCustomerActiveArgs = {
  updateCustomer: UpdateCustomerActiveInput
}

export type MutationUpdateEquipmentArgs = {
  data: EquipmentUpdateInput
  id: Scalars['ID']
}

export type MutationUpdateEstimateArgs = {
  data: EstimateUpdateInput
  id?: InputMaybe<Scalars['Int']>
}

export type MutationUpdateFileNameArgs = {
  data: UpdateFileNameInput
}

export type MutationUpdateFolderArgs = {
  folder: UpdateFolderInput
}

export type MutationUpdateInventoryItemArgs = {
  data: InventoryItemUpdateInput
  id: Scalars['ID']
}

export type MutationUpdateInvoiceArgs = {
  data: InvoiceUpdateInput
  id: Scalars['ID']
}

export type MutationUpdateJobArgs = {
  data: JobUpdateInput
  id: Scalars['ID']
}

export type MutationUpdateJobActiveArgs = {
  updateJobVar: UpdateJobActiveInput
}

export type MutationUpdateJobScheduleArgs = {
  data?: InputMaybe<UpdateJobScheduleInput>
  id: Scalars['ID']
}

export type MutationUpdateJobStatusArgs = {
  data?: InputMaybe<JobUpdateStatusInput>
  id: Scalars['ID']
}

export type MutationUpdateKitArgs = {
  data: KitUpdateInput
  id: Scalars['ID']
}

export type MutationUpdatePaymentArgs = {
  data: PaymentUpdateInput
  id: Scalars['ID']
}

export type MutationUpdatePayrateArgs = {
  data: PayrateUpdateInput
  id: Scalars['ID']
}

export type MutationUpdateQuoteArgs = {
  data: QuoteUpdateInput
  id?: InputMaybe<Scalars['Int']>
}

export type MutationUpdateRolePermissionArgs = {
  data?: InputMaybe<UpdateRolePermissionInput>
}

export type MutationUpdateSiteArgs = {
  data: SiteUpdateInput
  id: Scalars['ID']
}

export type MutationUpdateTimeEntryArgs = {
  id: Scalars['ID']
  timeEntry: TimeEntryUpdateInput
}

export type MutationUpdateTransactionArgs = {
  data: TransactionUpdateInput
  id: Scalars['ID']
}

export type MutationUpdateTransactionSingleArgs = {
  data: TransactionUpdateInput
  id: Scalars['ID']
}

export type MutationUpdateUserArgs = {
  user?: InputMaybe<UserInput>
}

export type MutationUpdateUserRoleArgs = {
  data?: InputMaybe<UserRoleInput>
  id?: InputMaybe<Scalars['ID']>
}

export type MutationUpdateVendorActiveArgs = {
  updateVendor: UpdateVendorActiveInput
}

export type MutationViewFileLinkArgs = {
  data: ViewFileLinkInput
}

export type MutationViewReceiptLinkArgs = {
  receiptId: Scalars['ID']
}

export type OtherJobsInput = {
  customer_id?: InputMaybe<Scalars['ID']>
  search?: InputMaybe<Scalars['String']>
  skip: Scalars['Int']
  take: Scalars['Int']
}

export type PaginatedAssembly = {
  __typename?: 'PaginatedAssembly'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Assembly>>
}

export type PaginatedCategoryKits = {
  __typename?: 'PaginatedCategoryKits'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<CategoryKit>>
}

export type PaginatedContacts = {
  __typename?: 'PaginatedContacts'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<Contact>>>
}

export type PaginatedCustomerRequests = {
  __typename?: 'PaginatedCustomerRequests'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<CustomerRequest>>>
}

export type PaginatedCustomers = {
  __typename?: 'PaginatedCustomers'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Customer>>
}

export type PaginatedEquipmentType = {
  __typename?: 'PaginatedEquipmentType'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<EquipmentType>>>
}

export type PaginatedEstimates = {
  __typename?: 'PaginatedEstimates'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Estimate>>
}

export type PaginatedFileSettings = {
  __typename?: 'PaginatedFileSettings'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<FileSettings>>
}

export type PaginatedInventoryItems = {
  __typename?: 'PaginatedInventoryItems'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Item>>
}

export type PaginatedInvoice = {
  __typename?: 'PaginatedInvoice'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Invoice>>
}

export type PaginatedJobEquipments = {
  __typename?: 'PaginatedJobEquipments'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<JobEquipment>>>
}

export type PaginatedJobFinancialTotal = {
  __typename?: 'PaginatedJobFinancialTotal'
  results?: Maybe<Array<Maybe<JobFinancialTotal>>>
  total?: Maybe<Scalars['Int']>
}

export type PaginatedJobFinancialTotals = {
  __typename?: 'PaginatedJobFinancialTotals'
  results?: Maybe<Array<Maybe<JobFinancialTotals>>>
  total?: Maybe<Scalars['Int']>
}

export type PaginatedJobTimeEntries = {
  __typename?: 'PaginatedJobTimeEntries'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<TimeEntry>>>
}

export type PaginatedJobTimeEntriesInput = {
  job_id: Scalars['ID']
  skip?: InputMaybe<Scalars['Int']>
  take: Scalars['Int']
}

export type PaginatedJobs = {
  __typename?: 'PaginatedJobs'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Job>>
}

export type PaginatedKits = {
  __typename?: 'PaginatedKits'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Kit>>
}

export type PaginatedLogs = {
  __typename?: 'PaginatedLogs'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<IntegrationLog>>>
}

export type PaginatedMaterials = {
  __typename?: 'PaginatedMaterials'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<Material>>>
}

export type PaginatedPayment = {
  __typename?: 'PaginatedPayment'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Payment>>
}

export type PaginatedPayrates = {
  __typename?: 'PaginatedPayrates'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<Payrate>>>
}

export type PaginatedQuotes = {
  __typename?: 'PaginatedQuotes'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Quote>>
}

export type PaginatedTimeEntryRequests = {
  __typename?: 'PaginatedTimeEntryRequests'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<TimeEntryRequest>>>
}

export type PaginatedTransactions = {
  __typename?: 'PaginatedTransactions'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<Transaction>>>
}

export type PaginatedUserFinancialTotal = {
  __typename?: 'PaginatedUserFinancialTotal'
  results?: Maybe<Array<Maybe<UserFinancialTotal>>>
  total?: Maybe<Scalars['Int']>
}

export type PaginatedUserJobFinancialTotal = {
  __typename?: 'PaginatedUserJobFinancialTotal'
  results?: Maybe<Array<Maybe<UserJobFinancialTotal>>>
  total?: Maybe<Scalars['Int']>
}

export type PaginatedUserRole = {
  __typename?: 'PaginatedUserRole'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<UserRole>>
}

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<User>>
}

export type PaginatedUsersWithRoles = {
  __typename?: 'PaginatedUsersWithRoles'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<UserWithRoles>>
}

export type PaginatedVendors = {
  __typename?: 'PaginatedVendors'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Vendor>>
}

export type PaginatedWeeklyTotals = {
  __typename?: 'PaginatedWeeklyTotals'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Maybe<WeeklyTotal>>>
}

export type Payment = {
  __typename?: 'Payment'
  created_at?: Maybe<Scalars['Date']>
  created_date?: Maybe<Scalars['Date']>
  customer?: Maybe<Customer>
  customer_id?: Maybe<Scalars['String']>
  deposit_account?: Maybe<Scalars['String']>
  id: Scalars['ID']
  last_updated_date?: Maybe<Scalars['Date']>
  payment_lines?: Maybe<Array<Maybe<PaymentLines>>>
  payment_method?: Maybe<Scalars['String']>
  private_note?: Maybe<Scalars['String']>
  qb_updateable?: Maybe<Scalars['Boolean']>
  ref_number?: Maybe<Scalars['Int']>
  total_amount?: Maybe<Scalars['Float']>
  transaction_date?: Maybe<Scalars['Date']>
}

export type PaymentInput = {
  customer_id?: InputMaybe<Scalars['String']>
  deposit_account?: InputMaybe<Scalars['String']>
  payment_lines?: InputMaybe<Array<InputMaybe<PaymentLineInput>>>
  payment_method?: InputMaybe<Scalars['String']>
  private_note?: InputMaybe<Scalars['String']>
  qb_updateable?: InputMaybe<Scalars['Boolean']>
  ref_number?: InputMaybe<Scalars['Int']>
  total_amount?: InputMaybe<Scalars['Float']>
  transaction_date?: InputMaybe<Scalars['Date']>
}

export type PaymentLineInput = {
  amount?: InputMaybe<Scalars['Float']>
  id?: InputMaybe<Scalars['String']>
  invoice_id?: InputMaybe<Scalars['String']>
  payment_id?: InputMaybe<Scalars['String']>
  payment_type?: InputMaybe<Scalars['String']>
}

export type PaymentLines = {
  __typename?: 'PaymentLines'
  amount?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  invoice_id?: Maybe<Scalars['String']>
  payment_id?: Maybe<Scalars['String']>
  payment_type?: Maybe<Scalars['String']>
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type PaymentQuery = {
  qb_job_id?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type PaymentUpdateInput = {
  customer_id?: InputMaybe<Scalars['String']>
  deposit_account?: InputMaybe<Scalars['String']>
  payment_lines?: InputMaybe<Array<InputMaybe<PaymentLineInput>>>
  payment_method?: InputMaybe<Scalars['String']>
  private_note?: InputMaybe<Scalars['String']>
  qb_updateable?: InputMaybe<Scalars['Boolean']>
  ref_number?: InputMaybe<Scalars['Int']>
  total_amount?: InputMaybe<Scalars['Float']>
  transaction_date?: InputMaybe<Scalars['Date']>
}

export type Payments = {
  __typename?: 'Payments'
  customer_id?: Maybe<Scalars['String']>
  deposit_account?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  payment_lines?: Maybe<Array<Maybe<PaymentLines>>>
  payment_method?: Maybe<Scalars['String']>
  private_note?: Maybe<Scalars['String']>
  qb_updateable?: Maybe<Scalars['Boolean']>
  ref_number?: Maybe<Scalars['String']>
  total_amount?: Maybe<Scalars['Float']>
  transaction_date?: Maybe<Scalars['Date']>
}

export type Payrate = {
  __typename?: 'Payrate'
  end_date?: Maybe<Scalars['Date']>
  hours_per_week?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  paid_for_overtime: Scalars['Boolean']
  rate: Scalars['Float']
  rate_type: RateType
  start_date: Scalars['Date']
  user_id: Scalars['ID']
}

export type PayrateUpdateInput = {
  end_date?: InputMaybe<Scalars['Date']>
  hours_per_week?: InputMaybe<Scalars['Float']>
  paid_for_overtime?: InputMaybe<Scalars['Boolean']>
  rate?: InputMaybe<Scalars['Float']>
  rate_type?: InputMaybe<RateType>
  start_date?: InputMaybe<Scalars['Date']>
  user_id?: InputMaybe<Scalars['ID']>
}

export type PayratesQuery = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
  userId: Scalars['ID']
}

export type PermissionInputItem = {
  checked: Scalars['Boolean']
  checkedDb?: InputMaybe<Scalars['Boolean']>
  role_permission_id?: InputMaybe<Scalars['String']>
  route_id: Scalars['String']
}

export enum Permissions {
  CustomerService = 'customer_service',
  Financial = 'financial'
}

export type ProjectFile = {
  __typename?: 'ProjectFile'
  created_at?: Maybe<Scalars['Date']>
  created_by?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  label?: Maybe<Scalars['String']>
  project_folder?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type ProjectFileCreateInput = {
  label: Scalars['String']
  project_folder: Scalars['ID']
  url: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  accounts?: Maybe<Array<Maybe<Account>>>
  assemblies?: Maybe<PaginatedAssembly>
  assembly?: Maybe<Assembly>
  cContactsByCustomer?: Maybe<PaginatedContacts>
  cCustomerRequests?: Maybe<PaginatedCustomerRequests>
  cGetCurrentContact?: Maybe<Contact>
  cGetCustomersJobs?: Maybe<PaginatedJobs>
  cSitesByCustomer?: Maybe<SitesQueryResults>
  categoryKits?: Maybe<PaginatedCategoryKits>
  company?: Maybe<Company>
  contact?: Maybe<Contact>
  contacts?: Maybe<PaginatedContacts>
  currentClock?: Maybe<TimeEntry>
  currentRunningClocks?: Maybe<Array<Maybe<TimeEntry>>>
  customer?: Maybe<Customer>
  customerRequests?: Maybe<PaginatedCustomerRequests>
  customerSites?: Maybe<Array<CustomerSite>>
  customers?: Maybe<PaginatedCustomers>
  depositAccounts?: Maybe<Array<Maybe<Account>>>
  employees?: Maybe<EmployeesQueryResults>
  equipmentTypes?: Maybe<PaginatedEquipmentType>
  equipments?: Maybe<EquipmentsPaginated>
  estimate?: Maybe<Estimate>
  estimates?: Maybe<PaginatedEstimates>
  fileSettings?: Maybe<PaginatedFileSettings>
  financialTotals?: Maybe<FinancialTotals>
  getCurrentUser?: Maybe<User>
  getQbConnectionState: QuickBooksAuthenticationState
  getQbConnectionURL: QuickBooksConnectionInfo
  getSignedDownloadLink: Scalars['String']
  globalSearchSite?: Maybe<Array<Maybe<GlobalSearchResults>>>
  integrationLogs?: Maybe<PaginatedLogs>
  inventoryAssetAccounts?: Maybe<Array<Maybe<Account>>>
  inventoryExpenseAccounts?: Maybe<Array<Maybe<Account>>>
  inventoryIncomeAccounts?: Maybe<Array<Maybe<Account>>>
  inventoryItems?: Maybe<PaginatedInventoryItems>
  invoice?: Maybe<Invoice>
  invoices?: Maybe<PaginatedInvoice>
  isContactEmailAvailable?: Maybe<Scalars['Boolean']>
  items?: Maybe<Array<Maybe<Item>>>
  job?: Maybe<Job>
  jobEquipmentHistory?: Maybe<PaginatedJobEquipments>
  jobEquipments?: Maybe<Array<Maybe<JobEquipment>>>
  jobFinancialTotals?: Maybe<PaginatedJobFinancialTotal>
  jobFolders?: Maybe<Array<Maybe<Folders>>>
  jobLaborCost?: Maybe<Scalars['Float']>
  jobSchedule?: Maybe<JobSchedule>
  jobSchedules?: Maybe<Array<Maybe<JobSchedule>>>
  jobTypes?: Maybe<JobTypesResult>
  jobs?: Maybe<PaginatedJobs>
  kit?: Maybe<Kit>
  kits?: Maybe<PaginatedKits>
  material?: Maybe<Material>
  materials?: Maybe<PaginatedMaterials>
  materialsByVendorId?: Maybe<PaginatedMaterials>
  myTotalTrackedMinutes?: Maybe<Scalars['Float']>
  nonInventoryIncomeAccounts?: Maybe<Array<Maybe<Account>>>
  paginatedJobTimeEntries?: Maybe<PaginatedJobTimeEntries>
  payment?: Maybe<Payment>
  paymentAccounts?: Maybe<Array<Maybe<Account>>>
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>
  payments?: Maybe<PaginatedPayment>
  payrates?: Maybe<PaginatedPayrates>
  quote?: Maybe<Quote>
  quotes?: Maybe<PaginatedQuotes>
  rolePermissions?: Maybe<Array<Maybe<RolePermission>>>
  roles?: Maybe<Array<Maybe<Role>>>
  routes?: Maybe<Array<Maybe<Route>>>
  scheduledJobs?: Maybe<Array<Maybe<Job>>>
  sites?: Maybe<SitesQueryResults>
  subCustomers?: Maybe<PaginatedCustomers>
  taxCodes?: Maybe<Array<Maybe<TaxCode>>>
  terms?: Maybe<Array<Maybe<Terms>>>
  timeEntries?: Maybe<Array<TimeEntry>>
  timeEntryRequestById?: Maybe<TimeEntryRequest>
  timeEntryRequests?: Maybe<PaginatedTimeEntryRequests>
  todaysJobs?: Maybe<Array<JobSchedule>>
  totalExpenses?: Maybe<TotalExpenses>
  totalTime?: Maybe<Scalars['Float']>
  totalTransactionCost?: Maybe<Scalars['Float']>
  transaction?: Maybe<Transaction>
  transactions?: Maybe<PaginatedTransactions>
  unassignedJobs?: Maybe<PaginatedJobs>
  unscheduledJobs?: Maybe<PaginatedJobs>
  user?: Maybe<User>
  userFinancialTotals?: Maybe<PaginatedUserFinancialTotal>
  userJobFinancialTotals?: Maybe<PaginatedUserJobFinancialTotal>
  userRole?: Maybe<UserRole>
  userRoles?: Maybe<PaginatedUserRole>
  users?: Maybe<PaginatedUsers>
  usersWithRoles?: Maybe<PaginatedUsersWithRoles>
  vendor?: Maybe<Vendor>
  vendors?: Maybe<PaginatedVendors>
  weeklyClockInTotals?: Maybe<WeeklyTotals>
  weeklyTotals?: Maybe<PaginatedWeeklyTotals>
}

export type QueryAccountsArgs = {
  data: AccountQuery
}

export type QueryAssembliesArgs = {
  data?: InputMaybe<AssemblyQuery>
}

export type QueryAssemblyArgs = {
  id: Scalars['ID']
}

export type QueryCContactsByCustomerArgs = {
  data: CContactFilter
}

export type QueryCCustomerRequestsArgs = {
  data: CCustomerRequestsInput
}

export type QueryCGetCustomersJobsArgs = {
  data: CGetCustomersJobsInput
}

export type QueryCSitesByCustomerArgs = {
  data: SitesQuery
}

export type QueryCategoryKitsArgs = {
  data?: InputMaybe<CategoryKitQuery>
}

export type QueryContactArgs = {
  id: Scalars['ID']
}

export type QueryContactsArgs = {
  data: ContactFilter
}

export type QueryCustomerArgs = {
  id: Scalars['ID']
}

export type QueryCustomerRequestsArgs = {
  data?: InputMaybe<CustomerRequestInput>
}

export type QueryCustomerSitesArgs = {
  customer_id: Scalars['String']
}

export type QueryCustomersArgs = {
  data?: InputMaybe<CustomersQuery>
}

export type QueryEmployeesArgs = {
  data: EmployeesQuery
}

export type QueryEquipmentTypesArgs = {
  data: EquipmentQuery
}

export type QueryEquipmentsArgs = {
  data?: InputMaybe<EquipmentsInput>
}

export type QueryEstimateArgs = {
  id: Scalars['ID']
}

export type QueryEstimatesArgs = {
  data?: InputMaybe<EstimateQuery>
}

export type QueryFinancialTotalsArgs = {
  end_date: Scalars['Date']
  start_date: Scalars['Date']
}

export type QueryGetSignedDownloadLinkArgs = {
  id: Scalars['ID']
}

export type QueryGlobalSearchSiteArgs = {
  searchParam: Scalars['String']
}

export type QueryIntegrationLogsArgs = {
  filters?: InputMaybe<IntegrationLogFilter>
}

export type QueryInventoryItemsArgs = {
  data?: InputMaybe<InventoryItemsQuery>
}

export type QueryInvoiceArgs = {
  id: Scalars['ID']
}

export type QueryInvoicesArgs = {
  data?: InputMaybe<InvoiceQuery>
}

export type QueryIsContactEmailAvailableArgs = {
  email: Scalars['String']
}

export type QueryItemsArgs = {
  data: ItemQuery
}

export type QueryJobArgs = {
  id: Scalars['ID']
}

export type QueryJobEquipmentHistoryArgs = {
  data: JobEquipmentHistoryInput
}

export type QueryJobEquipmentsArgs = {
  jobId: Scalars['ID']
}

export type QueryJobFinancialTotalsArgs = {
  data: JobFinancialTotalInput
}

export type QueryJobFoldersArgs = {
  job_id: Scalars['ID']
}

export type QueryJobLaborCostArgs = {
  job_id: Scalars['ID']
}

export type QueryJobScheduleArgs = {
  id: Scalars['ID']
}

export type QueryJobSchedulesArgs = {
  data: JobSchedulesInput
}

export type QueryJobsArgs = {
  data?: InputMaybe<JobsQuery>
}

export type QueryKitArgs = {
  id: Scalars['ID']
}

export type QueryKitsArgs = {
  data?: InputMaybe<KitQuery>
}

export type QueryMaterialArgs = {
  id: Scalars['ID']
}

export type QueryMaterialsArgs = {
  data: MaterialsQuery
}

export type QueryMaterialsByVendorIdArgs = {
  data: MaterialsVendorQuery
}

export type QueryMyTotalTrackedMinutesArgs = {
  endDate: Scalars['Date']
  startDate: Scalars['Date']
}

export type QueryPaginatedJobTimeEntriesArgs = {
  data: PaginatedJobTimeEntriesInput
}

export type QueryPaymentArgs = {
  id: Scalars['ID']
}

export type QueryPaymentsArgs = {
  data?: InputMaybe<PaymentQuery>
}

export type QueryPayratesArgs = {
  data: PayratesQuery
}

export type QueryQuoteArgs = {
  id: Scalars['ID']
}

export type QueryQuotesArgs = {
  data?: InputMaybe<QuoteQuery>
}

export type QueryRolePermissionsArgs = {
  data?: InputMaybe<RoleQuery>
}

export type QueryScheduledJobsArgs = {
  data: ScheduleInput
}

export type QuerySitesArgs = {
  data: SitesQuery
}

export type QuerySubCustomersArgs = {
  data?: InputMaybe<SubCustomersQuery>
}

export type QueryTimeEntriesArgs = {
  endDate: Scalars['Date']
  startDate: Scalars['Date']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryTimeEntryRequestByIdArgs = {
  id: Scalars['ID']
}

export type QueryTimeEntryRequestsArgs = {
  end_date?: InputMaybe<Scalars['Date']>
  skip?: InputMaybe<Scalars['Int']>
  start_date?: InputMaybe<Scalars['Date']>
  status?: InputMaybe<TimeEntryStatus>
  take: Scalars['Int']
  user_id?: InputMaybe<Scalars['ID']>
}

export type QueryTotalExpensesArgs = {
  data?: InputMaybe<TotalExpensesQuery>
}

export type QueryTotalTimeArgs = {
  job_id: Scalars['ID']
}

export type QueryTotalTransactionCostArgs = {
  job_id: Scalars['ID']
}

export type QueryTransactionArgs = {
  id: Scalars['ID']
}

export type QueryTransactionsArgs = {
  data?: InputMaybe<TransactionsQuery>
}

export type QueryUnassignedJobsArgs = {
  data: OtherJobsInput
}

export type QueryUnscheduledJobsArgs = {
  data: OtherJobsInput
}

export type QueryUserArgs = {
  id: Scalars['ID']
}

export type QueryUserFinancialTotalsArgs = {
  data: UserFinancialTotalInput
}

export type QueryUserJobFinancialTotalsArgs = {
  data: UserFinancialTotalInput
}

export type QueryUserRoleArgs = {
  id: Scalars['ID']
}

export type QueryUserRolesArgs = {
  data?: InputMaybe<UserRoleQuery>
}

export type QueryUsersArgs = {
  data?: InputMaybe<UsersQuery>
}

export type QueryUsersWithRolesArgs = {
  data?: InputMaybe<UsersQuery>
}

export type QueryVendorArgs = {
  id: Scalars['ID']
}

export type QueryVendorsArgs = {
  data: VendorsQuery
}

export type QueryWeeklyClockInTotalsArgs = {
  end_date: Scalars['Date']
  start_date: Scalars['Date']
  today: Scalars['Date']
  user_id: Scalars['ID']
}

export type QueryWeeklyTotalsArgs = {
  end_date: Scalars['Date']
  skip: Scalars['Int']
  start_date: Scalars['Date']
  take: Scalars['Int']
  user_id: Scalars['ID']
}

export type QuickBooksAuthenticationState = {
  __typename?: 'QuickBooksAuthenticationState'
  status: AuthenticationState
}

export type QuickBooksConnectionInfo = {
  __typename?: 'QuickBooksConnectionInfo'
  environment?: Maybe<Scalars['String']>
  realmId?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type QuickBooksConnectionInfoInput = {
  CompanyName?: InputMaybe<Scalars['String']>
  companyId?: InputMaybe<Scalars['String']>
  environment?: InputMaybe<Scalars['String']>
  error?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

export type QuickBooksTokensInput = {
  redirectUrl: Scalars['String']
}

export type Quote = {
  __typename?: 'Quote'
  address?: Maybe<Scalars['String']>
  billing_address_line1?: Maybe<Scalars['String']>
  billing_address_line2?: Maybe<Scalars['String']>
  billing_city?: Maybe<Scalars['String']>
  billing_postal_code?: Maybe<Scalars['String']>
  billing_state?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  contact_email?: Maybe<Scalars['String']>
  contact_id?: Maybe<Scalars['String']>
  contact_phone?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['Float']>
  created_at?: Maybe<Scalars['Date']>
  customer?: Maybe<Customer>
  customer_id?: Maybe<Scalars['String']>
  customer_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  equipment_cost?: Maybe<Scalars['Float']>
  exclusions?: Maybe<Scalars['String']>
  hours?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['ID']>
  inclusions?: Maybe<Scalars['String']>
  job_name?: Maybe<Scalars['String']>
  labor_cost?: Maybe<Scalars['Float']>
  material_cost?: Maybe<Scalars['Float']>
  phone?: Maybe<Scalars['String']>
  price_override?: Maybe<Scalars['Boolean']>
  quote_items?: Maybe<Array<Maybe<QuoteItem>>>
  sales_price?: Maybe<Scalars['Float']>
  state?: Maybe<Scalars['String']>
  tran_date?: Maybe<Scalars['Date']>
  visual_id?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type QuoteCostDetailLine = {
  __typename?: 'QuoteCostDetailLine'
  cost?: Maybe<Scalars['Float']>
  deleted?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  margin?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  optional?: Maybe<Scalars['Boolean']>
  price?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Int']>
  quote_item_id?: Maybe<Scalars['String']>
  quote_sub_item_id?: Maybe<Scalars['String']>
  total?: Maybe<Scalars['Float']>
  total_cost?: Maybe<Scalars['Float']>
  type?: Maybe<Scalars['String']>
}

export type QuoteCostDetailLineInput = {
  cost?: InputMaybe<Scalars['Float']>
  deleted?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  margin?: InputMaybe<Scalars['Float']>
  name?: InputMaybe<Scalars['String']>
  optional?: InputMaybe<Scalars['Boolean']>
  price?: InputMaybe<Scalars['Float']>
  quantity?: InputMaybe<Scalars['Int']>
  quote_item_id?: InputMaybe<Scalars['String']>
  quote_sub_item_id?: InputMaybe<Scalars['String']>
  total?: InputMaybe<Scalars['Float']>
  total_cost?: InputMaybe<Scalars['Float']>
  type?: InputMaybe<Scalars['String']>
}

export type QuoteInput = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  contact_email?: InputMaybe<Scalars['String']>
  contact_id?: InputMaybe<Scalars['String']>
  contact_phone?: InputMaybe<Scalars['String']>
  cost?: InputMaybe<Scalars['Float']>
  customer_id?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  equipment_cost?: InputMaybe<Scalars['Float']>
  exclusions?: InputMaybe<Scalars['String']>
  hours?: InputMaybe<Scalars['Float']>
  inclusions?: InputMaybe<Scalars['String']>
  job_name: Scalars['String']
  labor_cost?: InputMaybe<Scalars['Float']>
  material_cost?: InputMaybe<Scalars['Float']>
  phone?: InputMaybe<Scalars['String']>
  price_override?: InputMaybe<Scalars['Boolean']>
  quote_items?: InputMaybe<Array<InputMaybe<QuoteItemInput>>>
  sales_price?: InputMaybe<Scalars['Float']>
  state?: InputMaybe<Scalars['String']>
  tran_date?: InputMaybe<Scalars['Date']>
  visual_id?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type QuoteItem = {
  __typename?: 'QuoteItem'
  adjusted_cost?: Maybe<Scalars['Float']>
  cost?: Maybe<Scalars['Float']>
  cost_override?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  equipment_cost?: Maybe<Scalars['Float']>
  estimate_hour_rate?: Maybe<Scalars['Float']>
  estimate_hours?: Maybe<Scalars['Float']>
  hours?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  labor_cost?: Maybe<Scalars['Float']>
  margin?: Maybe<Scalars['Float']>
  material_cost?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  price_override?: Maybe<Scalars['Boolean']>
  quantity?: Maybe<Scalars['Int']>
  quote_cost_detail_lines?: Maybe<Array<Maybe<QuoteCostDetailLine>>>
  quote_id?: Maybe<Scalars['Int']>
  quote_sub_items?: Maybe<Array<Maybe<QuoteSubItem>>>
}

export type QuoteItemInput = {
  adjusted_cost?: InputMaybe<Scalars['Float']>
  cost?: InputMaybe<Scalars['Float']>
  cost_override?: InputMaybe<Scalars['Boolean']>
  deleted?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  equipment_cost?: InputMaybe<Scalars['Float']>
  estimate_hour_rate?: InputMaybe<Scalars['Float']>
  estimate_hours?: InputMaybe<Scalars['Float']>
  hours?: InputMaybe<Scalars['Float']>
  id?: InputMaybe<Scalars['String']>
  labor_cost?: InputMaybe<Scalars['Float']>
  margin?: InputMaybe<Scalars['Float']>
  material_cost?: InputMaybe<Scalars['Float']>
  name?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['Float']>
  price_override?: InputMaybe<Scalars['Boolean']>
  quantity?: InputMaybe<Scalars['Int']>
  quote_cost_detail_lines?: InputMaybe<Array<InputMaybe<QuoteCostDetailLineInput>>>
  quote_id?: InputMaybe<Scalars['Int']>
  quote_sub_items?: InputMaybe<Array<InputMaybe<QuoteSubItemInput>>>
}

export type QuoteQuery = {
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type QuoteSubItem = {
  __typename?: 'QuoteSubItem'
  cost?: Maybe<Scalars['Float']>
  cost_override?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  equipment_cost?: Maybe<Scalars['Float']>
  estimate_hour_rate?: Maybe<Scalars['Float']>
  estimate_hours?: Maybe<Scalars['Float']>
  hours?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  labor_cost?: Maybe<Scalars['Float']>
  margin?: Maybe<Scalars['Float']>
  material_cost?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  price_override?: Maybe<Scalars['Boolean']>
  quantity?: Maybe<Scalars['Int']>
  quote_cost_detail_lines?: Maybe<Array<Maybe<QuoteCostDetailLine>>>
  quote_item_id?: Maybe<Scalars['String']>
}

export type QuoteSubItemInput = {
  adjusted_cost?: InputMaybe<Scalars['Float']>
  cost?: InputMaybe<Scalars['Float']>
  cost_override?: InputMaybe<Scalars['Boolean']>
  deleted?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  equipment_cost?: InputMaybe<Scalars['Float']>
  estimate_hour_rate?: InputMaybe<Scalars['Float']>
  estimate_hours?: InputMaybe<Scalars['Float']>
  hours?: InputMaybe<Scalars['Float']>
  id?: InputMaybe<Scalars['String']>
  labor_cost?: InputMaybe<Scalars['Float']>
  margin?: InputMaybe<Scalars['Float']>
  material_cost?: InputMaybe<Scalars['Float']>
  name?: InputMaybe<Scalars['String']>
  price?: InputMaybe<Scalars['Float']>
  price_override?: InputMaybe<Scalars['Boolean']>
  quantity?: InputMaybe<Scalars['Int']>
  quote_cost_detail_lines?: InputMaybe<Array<InputMaybe<QuoteCostDetailLineInput>>>
  quote_item_id?: InputMaybe<Scalars['String']>
}

export type QuoteUpdateInput = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  contact_email?: InputMaybe<Scalars['String']>
  contact_id?: InputMaybe<Scalars['String']>
  contact_phone?: InputMaybe<Scalars['String']>
  cost?: InputMaybe<Scalars['Float']>
  customer_id?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  equipment_cost?: InputMaybe<Scalars['Float']>
  exclusions?: InputMaybe<Scalars['String']>
  hours?: InputMaybe<Scalars['Float']>
  inclusions?: InputMaybe<Scalars['String']>
  job_name?: InputMaybe<Scalars['String']>
  labor_cost?: InputMaybe<Scalars['Float']>
  material_cost?: InputMaybe<Scalars['Float']>
  phone?: InputMaybe<Scalars['String']>
  price_override?: InputMaybe<Scalars['Boolean']>
  quote_items?: InputMaybe<Array<InputMaybe<QuoteItemInput>>>
  sales_price?: InputMaybe<Scalars['Float']>
  state?: InputMaybe<Scalars['String']>
  tran_date?: InputMaybe<Scalars['Date']>
  visual_id?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export enum RateType {
  Hourly = 'hourly',
  Salary = 'salary'
}

export type RequestCreateInput = {
  job_id: Scalars['String']
  reason?: InputMaybe<Scalars['String']>
  requested_clock_in: Scalars['Date']
  requested_clock_out: Scalars['Date']
  time_entry_id?: InputMaybe<Scalars['ID']>
}

export type Role = {
  __typename?: 'Role'
  id: Scalars['ID']
  name: Scalars['String']
}

export type RoleCreateInput = {
  name: Scalars['String']
}

export type RoleInfo = {
  __typename?: 'RoleInfo'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type RolePermission = {
  __typename?: 'RolePermission'
  id?: Maybe<Scalars['ID']>
  role_id?: Maybe<Scalars['String']>
  route_id?: Maybe<Scalars['String']>
}

export type RoleQuery = {
  role_id: Scalars['String']
}

export type Route = {
  __typename?: 'Route'
  id: Scalars['ID']
  name: Scalars['String']
  path: Scalars['String']
  type: RouteTypeEnum
}

export type RouteCreateInput = {
  name: Scalars['String']
  path: Scalars['String']
  type: RouteTypeEnum
}

export enum RouteTypeEnum {
  Api = 'api',
  Client = 'client'
}

export type ScheduleInput = {
  endDate: Scalars['Date']
  site_id?: InputMaybe<Scalars['String']>
  startDate: Scalars['Date']
  user_id?: InputMaybe<Scalars['String']>
}

export type SignInResponse = {
  __typename?: 'SignInResponse'
  refreshToken: Scalars['String']
  token: Scalars['String']
  user: User
}

export type Site = {
  __typename?: 'Site'
  address?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  customer_sites?: Maybe<Array<CustomerSite>>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type SiteCreateInput = {
  address: Scalars['String']
  city: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  state: Scalars['String']
  zip: Scalars['String']
}

export type SiteUpdateInput = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type SitesQuery = {
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type SitesQueryResults = {
  __typename?: 'SitesQueryResults'
  count?: Maybe<Scalars['Int']>
  results?: Maybe<Array<Site>>
}

export type SubCustomersQuery = {
  customer_qbid?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type TaxCode = {
  __typename?: 'TaxCode'
  active?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  taxRates?: Maybe<Array<Maybe<TaxRate>>>
  tax_rate_ids?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TaxRate = {
  __typename?: 'TaxRate'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  quickbooks_id?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Float']>
}

export type TechJobSchedule = {
  __typename?: 'TechJobSchedule'
  id: Scalars['ID']
  job_schedule_id: Scalars['ID']
  user?: Maybe<User>
  user_id: Scalars['ID']
}

export type Terms = {
  __typename?: 'Terms'
  due_days?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
}

export type TimeEntry = {
  __typename?: 'TimeEntry'
  clock_in?: Maybe<Scalars['Date']>
  clock_out?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  job?: Maybe<Job>
  job_id?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  quickbooks_id?: Maybe<Scalars['String']>
  sync_token?: Maybe<Scalars['String']>
  time_entry_request?: Maybe<Array<Maybe<TimeEntryRequest>>>
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

export type TimeEntryCreateInput = {
  clock_in: Scalars['Date']
  clock_out: Scalars['Date']
  job_id: Scalars['String']
  note?: InputMaybe<Scalars['String']>
  user_id: Scalars['String']
}

export type TimeEntryRequest = {
  __typename?: 'TimeEntryRequest'
  created_at?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  job?: Maybe<Job>
  job_id: Scalars['ID']
  original_clock_in?: Maybe<Scalars['Date']>
  original_clock_out?: Maybe<Scalars['Date']>
  reason?: Maybe<Scalars['String']>
  requested_clock_in: Scalars['Date']
  requested_clock_out: Scalars['Date']
  status?: Maybe<TimeEntryStatus>
  time_entry_id?: Maybe<Scalars['ID']>
  user?: Maybe<User>
  user_id: Scalars['ID']
}

export enum TimeEntryStatus {
  Approved = 'approved',
  Denied = 'denied',
  Pending = 'pending'
}

export type TimeEntryStatusInput = {
  id: Scalars['ID']
  status?: InputMaybe<TimeEntryStatus>
}

export type TimeEntryUpdateInput = {
  clock_in?: InputMaybe<Scalars['Date']>
  clock_out?: InputMaybe<Scalars['Date']>
  job_id?: InputMaybe<Scalars['String']>
  note?: InputMaybe<Scalars['String']>
  user_id?: InputMaybe<Scalars['String']>
}

export type TotalExpenses = {
  __typename?: 'TotalExpenses'
  totalExpenses?: Maybe<Scalars['Float']>
}

export type TotalExpensesQuery = {
  end_date?: InputMaybe<Scalars['Date']>
  job_id?: InputMaybe<Scalars['String']>
  linked?: InputMaybe<Scalars['Boolean']>
  overhead_transaction?: InputMaybe<Scalars['Boolean']>
  start_date?: InputMaybe<Scalars['Date']>
  vendor_id?: InputMaybe<Scalars['String']>
}

export type Transaction = {
  __typename?: 'Transaction'
  created_at?: Maybe<Scalars['Date']>
  created_date?: Maybe<Scalars['Date']>
  date?: Maybe<Scalars['Date']>
  doc_number?: Maybe<Scalars['String']>
  id: Scalars['ID']
  job?: Maybe<Job>
  job_id?: Maybe<Scalars['String']>
  materials?: Maybe<Array<TransactionMaterial>>
  overhead_transaction?: Maybe<Scalars['Boolean']>
  payment_account?: Maybe<Scalars['String']>
  payment_type?: Maybe<Scalars['String']>
  transaction_lines?: Maybe<Array<Maybe<TransactionLine>>>
  transaction_materials?: Maybe<Array<Maybe<TransactionMaterial>>>
  transaction_receipts?: Maybe<Array<Maybe<TransactionReceipt>>>
  type: TransactionTypes
  vendor?: Maybe<Vendor>
  vendor_id?: Maybe<Scalars['String']>
}

export type TransactionCreateInput = {
  created_date?: InputMaybe<Scalars['Date']>
  date?: InputMaybe<Scalars['Date']>
  doc_number?: InputMaybe<Scalars['String']>
  job_id?: InputMaybe<Scalars['String']>
  materials?: InputMaybe<Array<InputMaybe<MaterialInput>>>
  overhead_transaction?: InputMaybe<Scalars['Boolean']>
  payment_account?: InputMaybe<Scalars['String']>
  payment_type?: InputMaybe<Scalars['String']>
  receipts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  transaction_lines?: InputMaybe<Array<InputMaybe<TransactionLineInput>>>
  type?: InputMaybe<TransactionTypes>
  vendor_id?: InputMaybe<Scalars['String']>
}

export type TransactionLine = {
  __typename?: 'TransactionLine'
  account_id?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  item_id?: Maybe<Scalars['String']>
  item_qty?: Maybe<Scalars['Float']>
  job_id?: Maybe<Scalars['String']>
  line_type?: Maybe<Scalars['String']>
  rate?: Maybe<Scalars['Float']>
  transaction_id?: Maybe<Scalars['String']>
}

export type TransactionLineInput = {
  account_id?: InputMaybe<Scalars['String']>
  amount?: InputMaybe<Scalars['Float']>
  deleted?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  item_id?: InputMaybe<Scalars['String']>
  item_qty?: InputMaybe<Scalars['Float']>
  job_id?: InputMaybe<Scalars['String']>
  line_type?: InputMaybe<Scalars['String']>
  rate?: InputMaybe<Scalars['Float']>
}

export type TransactionMaterial = {
  __typename?: 'TransactionMaterial'
  id: Scalars['ID']
  material?: Maybe<Material>
  material_id: Scalars['String']
  quantity?: Maybe<Scalars['Int']>
  total_cost?: Maybe<Scalars['Float']>
  transaction?: Maybe<Transaction>
  transaction_id: Scalars['String']
}

export type TransactionMaterialCreateInput = {
  material_id: Scalars['ID']
  quantity?: InputMaybe<Scalars['Int']>
  total_cost?: InputMaybe<Scalars['Float']>
  transaction_id: Scalars['ID']
}

export type TransactionReceipt = {
  __typename?: 'TransactionReceipt'
  file_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  transaction?: Maybe<Transaction>
  transaction_id?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type TransactionReceiptCreateInput = {
  file_name: Scalars['String']
  transaction_id: Scalars['ID']
  url: Scalars['String']
}

export enum TransactionTypes {
  Bill = 'bill',
  Expense = 'expense'
}

export type TransactionUpdateInput = {
  created_date?: InputMaybe<Scalars['Date']>
  date?: InputMaybe<Scalars['Date']>
  doc_number?: InputMaybe<Scalars['String']>
  job_id?: InputMaybe<Scalars['String']>
  overhead_transaction?: InputMaybe<Scalars['Boolean']>
  payment_account?: InputMaybe<Scalars['String']>
  payment_type?: InputMaybe<Scalars['String']>
  receipts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  transaction_lines?: InputMaybe<Array<InputMaybe<TransactionLineInput>>>
  transaction_materials?: InputMaybe<Array<InputMaybe<MaterialInput>>>
  transaction_receipts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  type?: InputMaybe<TransactionTypes>
  vendor_id?: InputMaybe<Scalars['String']>
}

export type TransactionsJobQuery = {
  jobId: Scalars['String']
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type TransactionsQuery = {
  end_date?: InputMaybe<Scalars['Date']>
  job_id?: InputMaybe<Scalars['String']>
  linked?: InputMaybe<Scalars['Boolean']>
  overhead_transaction?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  start_date?: InputMaybe<Scalars['Date']>
  take?: InputMaybe<Scalars['Int']>
  vendor_id?: InputMaybe<Scalars['String']>
}

export type UpdateCompanyInput = {
  default_cost_margin?: InputMaybe<Scalars['Float']>
  default_hour_wage?: InputMaybe<Scalars['Float']>
  default_inv_asset_account?: InputMaybe<Scalars['String']>
  default_inv_expense_account?: InputMaybe<Scalars['String']>
  default_inv_income_account?: InputMaybe<Scalars['String']>
  default_labor_margin?: InputMaybe<Scalars['Float']>
  default_non_inv_expense_account?: InputMaybe<Scalars['String']>
  default_non_inv_income_account?: InputMaybe<Scalars['String']>
  default_payment_account?: InputMaybe<Scalars['String']>
  default_payment_method?: InputMaybe<Scalars['String']>
  default_service_expense_account?: InputMaybe<Scalars['String']>
  default_service_income_account?: InputMaybe<Scalars['String']>
  default_tax_code?: InputMaybe<Scalars['String']>
  default_term_id?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateCustomerActiveInput = {
  active?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
}

export type UpdateFileNameInput = {
  id: Scalars['ID']
  label: Scalars['String']
}

export type UpdateFolderInput = {
  id?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  permissions?: InputMaybe<Array<InputMaybe<Permissions>>>
}

export type UpdateJobActiveInput = {
  active?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
}

export type UpdateJobScheduleInput = {
  end_time: Scalars['Date']
  start_time: Scalars['Date']
}

export type UpdateRolePermissionInput = {
  role_id: Scalars['String']
  values?: InputMaybe<Array<InputMaybe<PermissionInputItem>>>
}

export type UpdateVendorActiveInput = {
  active?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
}

export type UploadLink = {
  __typename?: 'UploadLink'
  pathToFile?: Maybe<Scalars['String']>
  sasLink?: Maybe<Scalars['String']>
}

export type User = {
  __typename?: 'User'
  email?: Maybe<Scalars['String']>
  employee?: Maybe<Employee>
  employee_id?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  id: Scalars['ID']
  inactive?: Maybe<Scalars['Boolean']>
  last_name?: Maybe<Scalars['String']>
  manager?: Maybe<User>
  manager_id?: Maybe<Scalars['String']>
  permissions?: Maybe<Array<Maybe<Permissions>>>
  phone?: Maybe<Scalars['PhoneNumber']>
  role?: Maybe<Scalars['String']>
  time_entries?: Maybe<Array<Maybe<TimeEntry>>>
}

export type UserCreateInput = {
  email: Scalars['String']
  first_name: Scalars['String']
  inactive?: InputMaybe<Scalars['Boolean']>
  last_name: Scalars['String']
  manager_id?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  permissions?: InputMaybe<Array<InputMaybe<Permissions>>>
  phone: Scalars['PhoneNumber']
  role?: InputMaybe<Scalars['String']>
}

export type UserFinancialTotal = {
  __typename?: 'UserFinancialTotal'
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  rate_type?: Maybe<Scalars['String']>
  total_cost?: Maybe<Scalars['Float']>
  total_overtime_hours?: Maybe<Scalars['Float']>
  total_regular_hours?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['ID']>
}

export type UserFinancialTotalInput = {
  end_date: Scalars['Date']
  job_id?: InputMaybe<Scalars['ID']>
  skip?: InputMaybe<Scalars['Int']>
  start_date: Scalars['Date']
  take: Scalars['Int']
  user_id?: InputMaybe<Scalars['ID']>
}

export type UserInput = {
  email?: InputMaybe<Scalars['String']>
  employee_id?: InputMaybe<Scalars['String']>
  first_name?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  inactive?: InputMaybe<Scalars['Boolean']>
  last_name?: InputMaybe<Scalars['String']>
  manager_id?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  permissions?: InputMaybe<Array<InputMaybe<Permissions>>>
  phone?: InputMaybe<Scalars['PhoneNumber']>
  role?: InputMaybe<Scalars['String']>
}

export type UserJobFinancialTotal = {
  __typename?: 'UserJobFinancialTotal'
  first_name?: Maybe<Scalars['String']>
  job_id?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  rate_type?: Maybe<Scalars['String']>
  total_cost?: Maybe<Scalars['Float']>
  total_overtime_hours?: Maybe<Scalars['Float']>
  total_regular_hours?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['ID']>
}

export type UserRole = {
  __typename?: 'UserRole'
  id?: Maybe<Scalars['ID']>
  role_id?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

export type UserRoleInput = {
  role_id: Scalars['String']
  user_id: Scalars['String']
}

export type UserRoleQuery = {
  search?: InputMaybe<Scalars['String']>
  showInactives?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type UserWithRoles = {
  __typename?: 'UserWithRoles'
  email?: Maybe<Scalars['String']>
  employee?: Maybe<Employee>
  employee_id?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  id: Scalars['ID']
  inactive?: Maybe<Scalars['Boolean']>
  last_name?: Maybe<Scalars['String']>
  permissions?: Maybe<Array<Maybe<Permissions>>>
  phone?: Maybe<Scalars['PhoneNumber']>
  role?: Maybe<Scalars['String']>
  roleInfo?: Maybe<Array<Maybe<RoleInfo>>>
  roles?: Maybe<Role>
  time_entries?: Maybe<Array<Maybe<TimeEntry>>>
}

export type UsersQuery = {
  inactive?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type Vendor = {
  __typename?: 'Vendor'
  active?: Maybe<Scalars['Boolean']>
  display_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  id: Scalars['ID']
  last_name?: Maybe<Scalars['String']>
  name: Scalars['String']
  phone?: Maybe<Scalars['PhoneNumber']>
  quickbooks_id?: Maybe<Scalars['String']>
}

export type VendorsQuery = {
  search?: InputMaybe<Scalars['String']>
  showInactives?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  take: Scalars['Int']
}

export type ViewFileLinkInput = {
  url?: InputMaybe<Scalars['String']>
}

export type WeeklyTotal = {
  __typename?: 'WeeklyTotal'
  clock_in?: Maybe<Scalars['Date']>
  clock_out?: Maybe<Scalars['Date']>
  duration_seconds?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  job?: Maybe<Job>
  job_id?: Maybe<Scalars['String']>
  time_entry_request?: Maybe<Array<Maybe<TimeEntryRequest>>>
}

export type WeeklyTotals = {
  __typename?: 'WeeklyTotals'
  daily?: Maybe<Scalars['Float']>
  weekly?: Maybe<Scalars['Float']>
}

export type CContactFilter = {
  active?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  take: Scalars['Int']
  title?: InputMaybe<Scalars['String']>
}

export type CCreateCustomerRequestInput = {
  additional_site_information?: InputMaybe<Scalars['String']>
  contact_id?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  issue?: InputMaybe<IssueEnum>
  issue_details?: InputMaybe<Scalars['String']>
  scheduling_information?: InputMaybe<Scalars['String']>
  site_id?: InputMaybe<Scalars['String']>
}

export type CCustomerRequestsInput = {
  skip?: InputMaybe<Scalars['Int']>
  take: Scalars['Int']
}

export type CGetCustomersJobsInput = {
  filter?: InputMaybe<CGetCustomersJobsInputFilter>
  skip: Scalars['Int']
  take: Scalars['Int']
}

export type CGetCustomersJobsInputFilter = {
  issue_type?: InputMaybe<IssueEnum>
  site_id?: InputMaybe<Scalars['ID']>
  sort_by_completed?: InputMaybe<Scalars['Boolean']>
  status?: InputMaybe<JobStatus>
}

export type CProfileUpdateInput = {
  email?: InputMaybe<Scalars['String']>
  first_name?: InputMaybe<Scalars['String']>
  last_name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['PhoneNumber']>
}

export type CUpdateCustomerRequestInput = {
  additional_site_information?: InputMaybe<Scalars['String']>
  contact_id?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  issue?: InputMaybe<IssueEnum>
  issue_details?: InputMaybe<Scalars['String']>
  scheduling_information?: InputMaybe<Scalars['String']>
  site_id?: InputMaybe<Scalars['String']>
}

export type DeleteProjectFileInput = {
  file_id?: InputMaybe<Scalars['ID']>
  url?: InputMaybe<Scalars['String']>
}

export type DeleteProjectFolderInput = {
  folder_id?: InputMaybe<Scalars['String']>
  folder_name?: InputMaybe<Scalars['String']>
  job_id?: InputMaybe<Scalars['ID']>
}

export type SearchFilter = {
  active?: InputMaybe<Scalars['Boolean']>
  customer_id?: InputMaybe<Scalars['ID']>
  job_schedule_count_sort?: InputMaybe<Scalars['Boolean']>
  noInvoice?: InputMaybe<Scalars['Boolean']>
  site_id?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<Array<InputMaybe<JobStatus>>>
  techs_assigned?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  type?: InputMaybe<Array<InputMaybe<JobTypes>>>
  user_id?: InputMaybe<Scalars['ID']>
}
