import { gql } from '@apollo/client'

export const JOBS = gql`
  query ($data: JobsQuery) {
    jobs(data: $data) {
      count
      results {
        id
        name
        status
        type
        issue
        additional_information
        issue_details
        files
        description
        label_id
        job_schedules {
          id
          job_id
          start_time
          end_time
          tech_job_schedule {
            user {
              last_name
              first_name
              id
            }
            id
            job_schedule_id
          }
        }
        customer_site {
          site_id
          customer {
            id
            first_name
            last_name
            company_name
          }
          site {
            name
            address
          }
        }
      }
    }
  }
`

export const UNSCHEDULED_JOBS = gql`
  query ($data: OtherJobsInput!) {
    unscheduledJobs(data: $data) {
      count
      results {
        id
        name
        status
        type
        issue
        additional_information
        issue_details
        description
        customer_site {
          site_id
          customer {
            id
            first_name
            last_name
            company_name
          }
          site {
            name
            address
          }
        }
        job_schedules {
          id
          job_id
          start_time
          end_time
          tech_job_schedule {
            user {
              last_name
              first_name
              id
            }
            id
            job_schedule_id
          }
        }
      }
    }
  }
`

export const UNASSIGNED_JOBS = gql`
  query ($data: OtherJobsInput!) {
    unassignedJobs(data: $data) {
      count
      results {
        id
        name
        status
        type
        issue
        additional_information
        issue_details
        description
        customer_site {
          site_id
          customer {
            id
            first_name
            last_name
            company_name
          }
          site {
            name
            address
          }
        }
      }
    }
  }
`

export const TODAYS_JOBS = gql`
  query {
    todaysJobs {
      id
      start_time
      end_time
      job {
        name
        status
        type
        description
        issue_details
        additional_information
        issue
        id
      }
      tech_job_schedule {
        id
        user {
          first_name
          last_name
          id
        }
      }
    }
  }
`
export const GLOBAL_SEARCH_SITE = gql`
  query ($searchParam: String!) {
    globalSearchSite(searchParam: $searchParam) {
      first_name
      last_name
      other
      email
      id
      type
    }
  }
`

export const JOB = gql`
  query ($id: ID!) {
    job(id: $id) {
      id
      name
      status
      type
      issue
      additional_information
      issue_details
      description
      label_id
      active
      customer_site {
        site_id
        id
        customer {
          id
          first_name
          last_name
          company_name
        }
        site {
          name
          address
        }
      }
    }
  }
`

export const CREATE_JOB = gql`
  mutation ($data: JobCreateInput, $customer_request_id: ID) {
    createJob(data: $data, customer_request_id: $customer_request_id) {
      id
      name
      customer_site {
        customer {
          id
        }
        site {
          id
        }
      }
      status
      type
      description
      issue
      additional_information
      issue_details
    }
  }
`

export const UPDATE_JOB = gql`
  mutation updateJob($id: ID!, $data: JobUpdateInput!) {
    updateJob(id: $id, data: $data) {
      id
      name
      description
      type
      issue
      additional_information
      issue_details
      status
      customer_site {
        id
        customer {
          id
          first_name
          last_name
          company_name
        }
        site {
          id
          name
          address
        }
      }
    }
  }
`

export const JOB_LABOR_COST = gql`
  query ($job_id: ID!) {
    jobLaborCost(job_id: $job_id)
  }
`

export const JOB_FOLDERS = gql`
  query ($job_id: ID!) {
    jobFolders(job_id: $job_id) {
      id
      job_id
      name
      permissions
      created_by
      project_files {
        id
        project_folder
        label
        url
        created_by
        created_at
        user {
          first_name
          last_name
        }
      }
    }
  }
`

export const CREATE_NEW_PROJECT_FOLDER = gql`
  mutation ($folder: CreateProjectFolderInput!) {
    createProjectFolder(folder: $folder)
  }
`

export const GENERATE_FILE_UPLOAD_LINK = gql`
  mutation ($data: GenerateFileInput!) {
    generateFileLink(data: $data) {
      sasLink
      pathToFile
    }
  }
`

export const CREATE_PROJECT_FILE = gql`
  mutation ($data: ProjectFileCreateInput!) {
    createProjectFile(data: $data)
  }
`

export const UPDATE_FILE_NAME = gql`
  mutation ($data: UpdateFileNameInput!) {
    updateFileName(data: $data)
  }
`

export const VIEW_FILE_LINK = gql`
  mutation ($data: ViewFileLinkInput!) {
    viewFileLink(data: $data)
  }
`

export const DELETE_PROJECT_FOLDER = gql`
  mutation ($data: deleteProjectFolderInput!) {
    deleteProjectFolder(data: $data)
  }
`

export const DELETE_PROJECT_FILE = gql`
  mutation ($data: deleteProjectFileInput!) {
    deleteProjectFile(data: $data)
  }
`

export const JOB_FINANCIAL_TOTALS = gql`
  query ($data: JobFinancialTotalInput!) {
    jobFinancialTotals(data: $data) {
      total
      results {
        job_id
        total_regular_hours
        total_overtime_hours
        total_cost
        name
      }
    }
  }
`

export const SCHEDULED_JOBS = gql`
  query ($data: ScheduleInput!) {
    scheduledJobs(data: $data) {
      id
      name
      status
      type
      issue
      additional_information
      issue_details
      description
      customer_site {
        site_id
        id
        customer {
          id
          first_name
          last_name
          company_name
        }
        site {
          name
          address
        }
      }
      job_schedules {
        id
        start_time
        end_time
        tech_job_schedule {
          user {
            first_name
            last_name
          }
        }
      }
    }
  }
`

export const UPDATE_JOB_ACTIVE = gql`
  mutation ($updateJobVar: UpdateJobActiveInput!) {
    updateJobActive(updateJobVar: $updateJobVar)
  }
`
