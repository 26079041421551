import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { Box, Badge, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material'
import { Notifications as NotificationIcon } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import { TIME_ENTRY_REQUESTS } from 'src/apollo/queries'
import X from 'src/icons/X'
import TimeRequestList from 'src/components/TimeRequestList'
import { PaginatedTimeEntryRequests, QueryTimeEntryRequestsArgs, TimeEntryStatus } from 'src/types/__generated__/typescript-operations'

const PREFIX = 'TopBar'

const classes = {
  root: `${PREFIX}-root`,
  badge: `${PREFIX}-badge`,
  notification: `${PREFIX}-notification`
}

const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    marginLeft: 2,
    display: 'flex'
  },
  [`& .${classes.badge}`]: {
    color: '#155EEF'
  },
  [`& .${classes.notification}`]: { color: 'grey' }
}))

const Notifications = () => {
  const { data } = useQuery<{ timeEntryRequests: PaginatedTimeEntryRequests }, QueryTimeEntryRequestsArgs>(TIME_ENTRY_REQUESTS, {
    variables: { status: TimeEntryStatus.Pending, take: 10 },
    pollInterval: 30000 // every 30 second
  })
  const [openModal, setOpenModal] = useState(false)
  return (
    <Root>
      <Box mr={2} onClick={() => setOpenModal(true)}>
        <IconButton>
          <Badge badgeContent={data?.timeEntryRequests?.count} color="secondary">
            <NotificationIcon className={classes.notification} />
          </Badge>
        </IconButton>
      </Box>
      {openModal && (
        <Dialog open={openModal} onBackdropClick={() => setOpenModal(false)} onClose={() => setOpenModal(false)}>
          <Box display="flex" justifyContent="space-between">
            <DialogTitle>Notifications</DialogTitle>
            <IconButton sx={{ alignItems: 'flex-start', color: 'black' }} onClick={() => setOpenModal(false)}>
              <X />
            </IconButton>
          </Box>
          <DialogContent sx={{ pt: 0 }}>
            <TimeRequestList queryVariables={{ status: TimeEntryStatus.Pending, take: 10 }} />
          </DialogContent>
        </Dialog>
      )}
    </Root>
  )
}

export default Notifications
