import type { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Drawer, Theme, useMediaQuery } from '@mui/material'
import { PeopleOutline as PeopleOutlineIcon, WorkOutline as WorkOutlineIcon, RoomOutlined, CalendarTodayOutlined } from '@mui/icons-material'
import { User as UserIcon, Settings as SettingsIcon, PieChart, Home } from 'react-feather'
import Scrollbar from 'src/components/Scrollbar'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import StoreIcon from '@mui/icons-material/Store'
import LocalMallIcon from '@mui/icons-material/LocalMall'
import ReceiptIcon from '@mui/icons-material/Receipt'
import DescriptionIcon from '@mui/icons-material/Description'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import NavSection from './NavSection'

interface NavBarProps {}

const sections = [
  {
    title: 'Application',
    items: [
      {
        title: 'Dashboard',
        adminRequired: true,
        icon: <Home />,
        path: '/'
      },
      {
        title: 'Clocks',
        adminRequired: false,
        icon: <PieChart />,
        path: '/clocks'
      },
      {
        title: 'Users',
        adminRequired: true,
        icon: <UserIcon />,
        path: '/admin/users'
      },
      {
        title: 'Customers',
        adminRequired: false,
        icon: <PeopleOutlineIcon />,
        path: '/customers'
      },
      {
        title: 'Sites',
        adminRequired: false,
        icon: <RoomOutlined />,
        path: '/sites'
      },
      {
        title: 'Jobs',
        adminRequired: false,
        icon: <WorkOutlineIcon />,
        path: '/jobs'
      },
      {
        title: 'Schedule',
        adminRequired: true,
        icon: <CalendarTodayOutlined />,
        path: '/schedule'
      },
      {
        title: 'Purchases',
        adminRequired: false,
        icon: <AccountBalanceWalletOutlinedIcon />,
        path: '/purchases'
      },
      {
        title: 'Time Entries',
        adminRequired: false,
        icon: <PieChart />,
        path: '/timeentries'
      },
      {
        title: 'Inventory',
        adminRequired: false,
        icon: <LocalMallIcon />,
        path: '/inventory/items'
      },
      {
        title: 'Estimates',
        adminRequired: false,
        icon: <ReceiptIcon />,
        path: '/estimates'
      },
      {
        title: 'Quotes',
        adminRequired: false,
        icon: <ReceiptIcon />,
        path: '/quotes'
      },
      {
        title: 'Vendors',
        adminRequired: false,
        icon: <StoreIcon />,
        path: '/vendors'
      },
      {
        title: 'Invoices',
        adminRequired: false,
        icon: <DescriptionIcon />,
        path: '/invoices'
      },
      {
        title: 'Payments',
        adminRequired: false,
        icon: <AttachMoneyIcon />,
        path: '/payments'
      },
      {
        title: 'Settings',
        adminRequired: true,
        icon: <SettingsIcon />,
        path: '/admin/settings'
      },
      {
        title: 'Permissions',
        adminRequired: true,
        icon: <SettingsIcon />,
        path: '/admin/permissions'
      }
    ]
  }
]

const NavBar: FC<NavBarProps> = () => {
  const location = useLocation()
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'rgba(242, 188, 0, 1)',
        color: 'white',
        paddingTop: '64px'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {sections.map((section, i) => (
            <NavSection
              key={i}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  )

  return (
    <>
      {/* DESKTOP DRAWER */}
      <Drawer
        sx={{}}
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            width: lgUp ? '175px' : '70px'
          }
        }}
        anchor="left"
        open
        variant="persistent"
      >
        {content}
      </Drawer>
    </>
  )
}

NavBar.propTypes = {}

export default NavBar
