import { styled } from '@mui/material/styles'
import { FC } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { AppBar, Box, Toolbar } from '@mui/material'
import BlackLogo from 'src/components/BlackLogo'
import useAuth from 'src/hooks/useAuth'

const PREFIX = 'TopBar'

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  mobileToolbar: `${PREFIX}-mobileToolbar`,
  mobileAppBar: `${PREFIX}-mobileAppBar`,
  show: `${PREFIX}-show`,
  hide: `${PREFIX}-hide`,
  divider: `${PREFIX}-divider`,
  badge: `${PREFIX}-badge`,
  main: `${PREFIX}-main`
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    zIndex: theme.zIndex.drawer - 1,
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white
  },
  [`& .${classes.main}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  [`& .${classes.badge}`]: {
    borderRadius: '50%',
    color: '#0F1010',
    height: '38px',
    width: '38px',
    backgroundColor: '#FDEEB7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #F2C937'
  },
  [`& .${classes.toolbar}`]: {
    // minHeight: 56,
    // display: 'block',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%'
  },

  [`& .${classes.mobileToolbar}`]: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  [`& .${classes.mobileAppBar}`]: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'black'
  },

  [`& .${classes.show}`]: {
    transform: 'translateY(0)',
    transition: 'transform .5s'
  },

  [`& .${classes.hide}`]: {
    transform: 'translateY(-110%)',
    transition: 'transform .5s'
  },

  [`& .${classes.divider}`]: {
    borderRight: 'solid white 1px',
    borderColor: 'white'
  }
}))

interface TopBarProps {
  className?: string
}

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const { user } = useAuth()
  return (
    <Root>
      <AppBar className={clsx(classes.root, className)} {...rest} position="fixed">
        <Toolbar className={classes.toolbar}>
          <Box className={classes.main}>
            <BlackLogo />
            <Box className={classes.badge}>
              {user?.first_name?.slice(0, 1)}
              {user?.last_name?.slice(0, 1)}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Root>
  )
}
TopBar.propTypes = {
  className: PropTypes.string
}

export default TopBar
