import React from 'react'
import type { FC } from 'react'

interface LogoProps {
  [key: string]: any
}

const Logo: FC<LogoProps> = (props) => <img alt="Logo" height={40} src="/static/images/icon.png" {...props} />

export default Logo
