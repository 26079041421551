import { gql } from '@apollo/client'

export const CREATE_TIME_ENTRY_REQUEST = gql`
  mutation ($data: RequestCreateInput!) {
    createTimeEntryRequest(data: $data) {
      id
      original_clock_in
      original_clock_out
      requested_clock_in
      requested_clock_out
      reason
      time_entry_id
      status
      job_id
      user_id
    }
  }
`
export const TIME_ENTRY_REQUESTS = gql`
  query ($status: TimeEntryStatus, $skip: Int, $take: Int!) {
    timeEntryRequests(status: $status, skip: $skip, take: $take) {
      count
      results {
        id
        created_at
        time_entry_id
        original_clock_in
        original_clock_out
        requested_clock_in
        requested_clock_out
        reason
        user {
          id
          first_name
          last_name
        }
        job {
          id
          name
        }
        user_id
      }
    }
  }
`

export const TIME_REQUEST_BY_ID = gql`
  query ($id: ID!) {
    timeEntryRequestById(id: $id) {
      id
      created_at
      time_entry_id
      requested_clock_in
      requested_clock_out
      original_clock_in
      original_clock_out
      reason
      job {
        name
        id
      }
      user {
        id
        first_name
        last_name
      }
      user_id
    }
  }
`
export const TIME_ENTRY_REQUEST_STATUS = gql`
  mutation ($data: TimeEntryStatusInput!) {
    timeEntryRequestStatus(data: $data) {
      id
      original_clock_in
      original_clock_out
      requested_clock_in
      requested_clock_out
      reason
      time_entry_id
      status
      job_id
      user_id
    }
  }
`
