export * from './readFile'
export * from './system'
export * from './validation'
export * from './helpers'
export * from '../apollo/apollo'
export * from './timeHelpers'

export const formatCurrency = (amount: number | string) =>
  Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })

export const ensureTrailingZero = (str) => {
  // Check if the string ends with a period
  if (!str) return str
  if (str.endsWith('.')) {
    // Append a '0' to the string
    return `${str}0`
  }
  // Return the original string if no changes are needed
  return str
}

export const formatClock = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60
  return {
    hours: hours?.toString()?.length > 1 ? hours : `0${hours}`,
    minutes: minutes?.toString()?.length > 1 ? minutes : `0${minutes}`,
    seconds: seconds?.toString()?.length > 1 ? seconds : `0${seconds}`
  }
}

export const formatDate = (dateString) => {
  if (!dateString) return ''
  const date = new Date(dateString)
  return date?.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
}

export const removeAdditionsFromNumber = (str) => {
  if (!str || typeof str === 'number') return str

  return Number(str?.replace(/[$,]/g, '') || 0)
}

export function addOneDay(date) {
  if (date) {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 1)
    return newDate
  }
  return null
}
