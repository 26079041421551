import { gql } from '@apollo/client'

export const CUSTOMER_SITES = gql`
  query ($customer_id: String!) {
    customerSites(customer_id: $customer_id) {
      id
      customer {
        id
      }
      site {
        id
        name
        address
        city
        state
        zip
      }
    }
  }
`

export const CREATE_CUSTOMER_SITE = gql`
  mutation ($data: CreateCustomerSiteInput!) {
    createCustomerSite(data: $data) {
      id
      site_id
      customer_id
    }
  }
`
