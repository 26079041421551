import { styled } from '@mui/material/styles'
import { FC, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { AppBar, Box, CircularProgress, Drawer, IconButton, SvgIcon, Toolbar, Typography } from '@mui/material'
import Logo from 'src/components/Logo'
import Search from 'src/components/Search'
import { GLOBAL_SEARCH_SITE } from 'src/apollo/queries'
import { useLazyQuery } from '@apollo/client'
import { Close } from '@mui/icons-material'
import Account from './Account'
import Notifications from './Notification'

const PREFIX = 'TopBar'

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  mobileToolbar: `${PREFIX}-mobileToolbar`,
  mobileAppBar: `${PREFIX}-mobileAppBar`,
  show: `${PREFIX}-show`,
  hide: `${PREFIX}-hide`,
  divider: `${PREFIX}-divider`,
  badge: `${PREFIX}-badge`
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white
  },

  [`& .${classes.toolbar}`]: {
    minHeight: 64
  },

  [`& .${classes.mobileToolbar}`]: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  [`& .${classes.mobileAppBar}`]: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'black'
  },

  [`& .${classes.show}`]: {
    transform: 'translateY(0)',
    transition: 'transform .5s'
  },

  [`& .${classes.hide}`]: {
    transform: 'translateY(-110%)',
    transition: 'transform .5s'
  },

  [`& .${classes.divider}`]: {
    borderRight: 'solid white 1px',
    borderColor: 'white'
  }
}))

interface TopBarProps {
  className?: string
}

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const [refetch, { data, loading }] = useLazyQuery(GLOBAL_SEARCH_SITE)
  const [isOpen, setOpen] = useState(false)
  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
  }
  const linkToSearch = (result: any) => {
    if (result.type === 'Job') navigate(`/job/${result.id}`)
    if (result.type === 'User') navigate(`/user/${result.id}`)
    if (result.type === 'Customer') navigate(`/customer/${result.id}`)
    if (result.type === 'Contact') navigate(`/customer/${result.other}/contacts`)
    if (result.type === 'Site') navigate('/sites')
  }
  const displaySearch = (result: any) => {
    if (result.type === 'User' || result.type === 'Contact') {
      return (
        <Box>
          <Typography variant="body2" color="textPrimary" sx={{ ':hover': { cursor: 'pointer', textDecoration: 'underline' } }}>
            {`${result.type}: ${result.first_name} ${result.last_name} ${result.email}`}
          </Typography>
        </Box>
      )
    }
    if (result.type === 'Site') {
      return (
        <Box>
          <Typography variant="body2" color="textPrimary" sx={{ ':hover': { cursor: 'pointer', textDecoration: 'underline' } }}>
            {`Site: ${result.first_name} ${result.last_name}`}
          </Typography>
        </Box>
      )
    }
    if (result.type === 'Job') {
      return (
        <Box>
          <Typography variant="body2" color="textPrimary" sx={{ ':hover': { cursor: 'pointer', textDecoration: 'underline' } }}>
            {`Job: ${result.first_name}`}
          </Typography>
        </Box>
      )
    }
    return (
      <Box>
        <Typography variant="body2" color="textPrimary" sx={{ ':hover': { cursor: 'pointer', textDecoration: 'underline' } }}>
          {`Customer: ${result.email} - ${result.first_name} ${result.last_name}`}
        </Typography>
      </Box>
    )
  }
  return (
    <Root>
      <AppBar className={clsx(classes.root, className)} {...rest} position="fixed">
        <Toolbar className={classes.toolbar}>
          <Box sx={{ ml: 5 }}>
            <Logo />
          </Box>
          <Box flexGrow={1} />
          <Search
            onSearch={(val) => {
              setOpen(true)
              refetch({
                variables: {
                  searchParam: val
                }
              })
            }}
          />
          <Box flexGrow={10} />
          <Notifications />
          <Box ml={2} display="flex">
            <Account />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" onClose={handleClose} open={isOpen} variant="temporary">
        <Box p={2} maxWidth={500} minWidth={400}>
          <Box display="flex" justifyContent="space-between" alignItems="center" pt={6}>
            <Typography variant="h6" color="textPrimary">
              Search
            </Typography>
            <IconButton onClick={handleClose}>
              <SvgIcon fontSize="small">
                <Close />
              </SvgIcon>
            </IconButton>
          </Box>
          <Box mt={4}>
            <>
              {data?.globalSearchSite?.map((result, i) => (
                <Box key={i} mb={2} onClick={() => linkToSearch(result)}>
                  {displaySearch(result)}
                </Box>
              ))}
              {loading && (
                <Box justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {data?.globalSearchSite?.length === 0 && <Typography>No results found</Typography>}
            </>
          </Box>
        </Box>
      </Drawer>
    </Root>
  )
}
TopBar.propTypes = {
  className: PropTypes.string
}

export default TopBar
