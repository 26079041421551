import type { FC, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useMediaQuery, useTheme } from '@mui/material'
import { isMobile } from 'react-device-detect'
import useAuth from '../hooks/useAuth'

interface AdminGuardProps {
  children?: ReactNode
}

const AdminGuard: FC<AdminGuardProps> = ({ children }) => {
  const auth = useAuth()
  const { isAdmin, isAuthenticated } = auth
  const theme = useTheme()
  const location = useLocation()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  if (isAuthenticated && !location?.pathname?.includes('/clocks')) {
    if (isMobile || mobileDevice) return <Navigate to="/clocks" />
  }

  if (!isAdmin) {
    return <Navigate to="/" />
  }

  return <>{children}</>
}

AdminGuard.propTypes = {
  children: PropTypes.node
}

export default AdminGuard
