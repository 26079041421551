import { gql } from '@apollo/client'

export const CUSTOMERS = gql`
  query ($data: CustomersQuery) {
    customers(data: $data) {
      count
      results {
        id
        email
        active
        company_name
        first_name
        last_name
        phone
        billing_postal_code
        billing_country
        billing_city
        billing_state
        billing_address_line1
        billing_address_line2
        default_terms_id
        fully_qualified_name
        default_tax_code
        taxable
      }
    }
  }
`
export const CUSTOMER = gql`
  query ($id: ID!) {
    customer(id: $id) {
      id
      email
      active
      company_name
      first_name
      last_name
      phone
      billing_postal_code
      billing_country
      billing_city
      billing_state
      billing_address_line1
      billing_address_line2
      default_terms_id
      fully_qualified_name
      default_tax_code
      taxable
      customer_sites {
        id
        jobs {
          id
          name
          status
        }
        site {
          id
          name
          address
          city
          state
          zip
        }
      }
    }
  }
`

export const SUB_CUSTOMER = gql`
  query ($data: SubCustomersQuery) {
    subCustomers(data: $data) {
      count
      results {
        id
        email
        active
        first_name
        last_name
        company_name
        phone
        parent_customer {
          id
        }
      }
    }
  }
`

export const UPDATE_CUSTOMER_ACTIVE = gql`
  mutation ($updateCustomer: UpdateCustomerActiveInput!) {
    updateCustomerActive(updateCustomer: $updateCustomer) {
      id
    }
  }
`
