import { useState } from 'react'
import type { FC, ReactNode } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Button, Collapse, IconButton, ListItem, Theme, Tooltip, useMediaQuery } from '@mui/material'
import ChevronDownIcon from 'src/icons/ChevronDown'
import ChevronRightIcon from 'src/icons/ChevronRight'

interface NavItemProps {
  active?: boolean
  children?: ReactNode
  depth: number
  icon?: ReactNode
  open?: boolean
  path?: string
  title: string
}

const NavItem: FC<NavItemProps> = ({ active, children, depth, icon, open: openProp, path, title, ...rest }) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  const [open, setOpen] = useState<boolean>(openProp)
  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen)
  }

  let paddingLeft = 8

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0
        }}
        {...rest}
      >
        <Button
          endIcon={!open ? <ChevronRightIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '12px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%'
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    )
  }

  return (
    <ListItem
      data-cy={title}
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
    >
      {lgUp ? (
        <Button
          component={path && RouterLink}
          startIcon={icon}
          sx={{
            color: 'white',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            textAlign: 'left',
            pl: '14px',
            py: '12px',
            textTransform: 'none',
            width: '100%',
            borderRadius: '25px',
            ...(active && {
              color: '#614B00',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              fontWeight: 'fontWeightBold',
              '& svg': {
                color: '#614B00'
              }
            })
          }}
          variant="text"
          to={path}
        >
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none', lg: 'block' } }}>{title}</Box>
        </Button>
      ) : (
        <Tooltip title={title}>
          <IconButton
            component={path && RouterLink}
            to={path}
            sx={{
              color: 'white',
              fontWeight: 'fontWeightMedium',
              justifyContent: 'flex-start',
              textAlign: 'left',
              my: '5px',
              textTransform: 'none',
              ...(active && {
                color: '#614B00',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                fontWeight: 'fontWeightBold',
                '& svg': {
                  color: '#614B00'
                }
              })
            }}
          >
            {icon}
          </IconButton>
        </Tooltip>
      )}
    </ListItem>
  )
}

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
}

NavItem.defaultProps = {
  active: false,
  open: false
}

export default NavItem
