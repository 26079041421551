import { styled } from '@mui/material/styles'
import { Box, useMediaQuery, Theme, useTheme } from '@mui/material'
import type { FC, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import DashboardSidebar from './DashboardSidebar/DashboardSidebar'
import TopBar from './TopBar'
import MobileTopBar from './MobileTopBar'

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
}))

interface DashboardLayoutProps {
  children?: ReactNode
}

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))
  const getPaddingLeft = () => {
    if (isMobile || mobileDevice) return ''
    if (lgUp) return '220px'
    return '70px'
  }
  return (
    <Root>
      {isMobile || mobileDevice ? <MobileTopBar /> : <TopBar />}

      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          paddingLeft: getPaddingLeft(),
          marginTop: '64px'
        }}
      >
        {!isMobile && !mobileDevice && <DashboardSidebar />}
        <Box flex="1 1 auto" height="100%" overflow="hidden">
          <Outlet />
        </Box>
      </Box>
    </Root>
  )
}

export default DashboardLayout
