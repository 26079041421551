import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Paper, Button, Input } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const PREFIX = 'Search'

const classes = {
  root: `${PREFIX}-root`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  searchInput: `${PREFIX}-searchInput`,
  searchButton: `${PREFIX}-searchButton`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center'
  },

  [`& .${classes.search}`]: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },

  [`& .${classes.searchIcon}`]: {
    marginRight: theme.spacing(2)
  },

  [`& .${classes.searchInput}`]: {
    flexGrow: 1
  },

  [`& .${classes.searchButton}`]: {
    marginLeft: theme.spacing(2)
  }
}))

type IPropsSearch = {
  onSearch: any
  className?: any
  showButton?: boolean
  style?: {}
}

const Search = (props: IPropsSearch) => {
  const { onSearch, className, showButton, style, ...rest } = props
  const [search, setSearch] = useState('')
  const handleSearch = (value: string) => {
    setSearch(value)
  }

  return (
    <Root {...rest} className={clsx(classes.root, className)}>
      <Paper className={classes.search} elevation={1} sx={style}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          className={classes.searchInput}
          disableUnderline
          placeholder="Search"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') onSearch(search)
          }}
        />
      </Paper>
      {showButton && (
        <Button className={classes.searchButton} onClick={() => onSearch(search)} size="large" variant="contained">
          Search
        </Button>
      )}
    </Root>
  )
}

Search.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func
}

export default Search
