import { gql } from '@apollo/client'

export const UPDATE_COMPANY = gql`
  mutation ($company: UpdateCompanyInput!) {
    updateCompany(company: $company) {
      id
      name
      quickbooks_id
      default_payment_method
      default_payment_account
      default_hour_wage
      default_cost_margin
      default_labor_margin
      default_inv_asset_account
      default_inv_income_account
      default_inv_expense_account
      default_non_inv_income_account
      default_non_inv_expense_account
      default_service_income_account
      default_service_expense_account
      default_term_id
      default_tax_code
      quickbooks_connection_info {
        url
      }
    }
  }
`

export const COMPANY = gql`
  query {
    company {
      id
      name
      quickbooks_id
      default_payment_method
      default_payment_account
      default_hour_wage
      default_cost_margin
      default_labor_margin
      default_inv_asset_account
      default_inv_income_account
      default_inv_expense_account
      default_non_inv_income_account
      default_non_inv_expense_account
      default_service_income_account
      default_service_expense_account
      default_term_id
      default_tax_code
      quickbooks_connection_info {
        url
        environment
        realmId
        status
      }
    }
  }
`
