import React, { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from 'src/hooks/useAuth'
import { isMobile } from 'react-device-detect'
import { useMediaQuery, useTheme } from '@mui/material'

interface GuestGuardProps {
  children: ReactNode
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))
  if (isAuthenticated) {
    if (isMobile || mobileDevice) return <Navigate to="/clocks" />
    return <Navigate to="/" />
  }

  return <>{children}</>
}

GuestGuard.propTypes = {
  children: PropTypes.node
}

export default GuestGuard
