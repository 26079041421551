import React, { useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import toast from 'react-hot-toast'
import { Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Typography } from '@mui/material'
import useAuth from 'src/hooks/useAuth'

const PREFIX = 'Account'

const classes = {
  avatar: `${PREFIX}-avatar`,
  popover: `${PREFIX}-popover`
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.avatar}`]: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },

  [`& .${classes.popover}`]: {
    width: 200
  }
}))

const Account: FC = () => {
  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)
  const { logout, user } = useAuth()

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleLogout = async (): Promise<void> => {
    try {
      logout()
    } catch (err) {
      console.error(err)
      toast.error('Unable to logout')
    }
  }

  return (
    <Root>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Avatar alt="User" className={classes.avatar} />
        <Hidden smDown>
          <Typography variant="h6" color="inherit" />
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{
          className: classes.popover
        }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to={`user/${user?.id}`}>
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Root>
  )
}

export default Account
