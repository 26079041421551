import type { FC, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { useMediaQuery, useTheme } from '@mui/material'
import useAuth from '../hooks/useAuth'

interface AuthGuardProps {
  children?: ReactNode
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const theme = useTheme()

  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))
  const location = useLocation()
  if (isAuthenticated && !location?.pathname?.includes('/clocks')) {
    if (isMobile || mobileDevice) return <Navigate to="/clocks" />
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />
  }

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node
}

export default AuthGuard
