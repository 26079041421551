import { format, intervalToDuration } from 'date-fns'

export const getDigitalTime = (seconds?: boolean) => format(new Date(), `h:mm${seconds ? ':ss' : ''} a`)

export const getTimeElapsed = ({ datetime }: { datetime: string }) =>
  intervalToDuration({
    start: new Date(datetime),
    end: new Date()
  })

export const getDurationDifference = ({ dateFrom, dateTo }: { dateFrom: Date; dateTo: Date }) => {
  intervalToDuration({
    start: new Date(dateFrom),
    end: new Date(dateTo)
  })
}

export const greeting = (date: Date) => {
  // "Morning" is from 5am until noon
  // "Afternoon" is from noon until 5pm
  // "Evening" is from 5pm until 5am
  // I didn't include "Night" because "Good night" is not a saulutation. It conveys more of a farewell, which isn't the intent of this greeting.
  const hour = date.getHours()
  if (hour >= 5 && hour < 12) {
    return 'Good Morning'
  }
  if (hour >= 12 && hour < 17) {
    return 'Good Afternoon'
  }
  if ((hour >= 17 && hour <= 23) || hour < 5) {
    return 'Good Evening'
  }
  return ''
}

export const toLocalDate = (date: string | Date): Date => new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString())
export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions()?.timeZone

export const formatTime = (amt: string) => {
  if (amt === '') return amt
  if (Number(amt) === 0) return '00'
  if (Number(amt) < 10) return `0${Number(amt)}`
  return amt
}

export const secondsToHoursMinutes = (seconds) => {
  if (!seconds) return 0
  const hours = seconds / 3600
  return `${hours?.toFixed(2)} hr`
}

export const secondsToHoursMinutesNum = (seconds) => {
  if (!seconds) return 0
  const hours = seconds / 3600
  return hours?.toFixed(2)
}
