import type { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { matchPath } from 'react-router-dom'
import { List } from '@mui/material'
import type { ListProps } from '@mui/material'
import useAuth from 'src/hooks/useAuth'
import NavItem from './NavItem'

interface Item {
  path?: string
  icon?: ReactNode
  info?: ReactNode
  adminRequired?: boolean
  children?: Item[]
  title: string
}

interface NavSectionProps extends ListProps {
  items: Item[]
  pathname: string
}

const renderNavItems = ({ depth = 0, items, pathname }: { items: Item[]; pathname: string; depth?: number }): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      (acc, item) =>
        ReduceChildRoutes({
          acc,
          item,
          pathname,
          depth
        }),
      []
    )}
  </List>
)

const ReduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth
}: {
  acc: JSX.Element[]
  pathname: string
  item: Item
  depth: number
}): Array<JSX.Element> => {
  const { isAdmin } = useAuth()
  const key = `${item.title}-${depth}`
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true
        },
        pathname
      )
    : false

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false
          },
          pathname
        )
      : false

    acc.push(
      <NavItem active={partialMatch} depth={depth} icon={item.icon} key={key} open={partialMatch} path={item.path} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </NavItem>
    )
  } else if ((!isAdmin && !item.adminRequired) || isAdmin) {
    acc.push(<NavItem active={exactMatch} depth={depth} icon={item.icon} key={key} path={item.path} title={item.title} />)
  }

  return acc
}

const NavSection: FC<NavSectionProps> = (props) => {
  const { items, pathname, ...other } = props

  return (
    <List {...other}>
      {renderNavItems({
        items,
        pathname
      })}
    </List>
  )
}

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string
}

export default NavSection
