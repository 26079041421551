import { gql } from '@apollo/client'

export const QB_LOGIN_URL = gql`
  query {
    getQbConnectionURL {
      url
    }
  }
`
export const QB_LOGIN_TOKENS = gql`
  mutation ($data: QuickBooksTokensInput!) {
    authenticateAndSyncQbTokens(data: $data) {
      status
    }
  }
`
export const QB_CONNECTION_STATUS = gql`
  query {
    getQbConnectionState {
      status
    }
  }
`

export const DISCONNECT_QB = gql`
  mutation {
    disconnectQb {
      status
    }
  }
`
