import { getInitials } from 'src/utils'
import { format } from 'date-fns'
import { ListItem, Avatar, Typography, Link, Box, List } from '@mui/material'
import { TIME_ENTRY_REQUESTS } from 'src/apollo/queries/timeEntryRequest'
import { useQuery } from '@apollo/client'
import { PaginatedTimeEntryRequests, QueryTimeEntryRequestsArgs } from 'src/types/__generated__/typescript-operations'
import { useState } from 'react'
import TimeEntryRequestModal from './TimeEntryRequestModal'

type TimeRequestListProps = {
  queryVariables?: QueryTimeEntryRequestsArgs
}

const TimeRequestList = ({ queryVariables }: TimeRequestListProps) => {
  const [timeRequestSelected, setTimeRequestSelected] = useState(null)
  const { data } = useQuery<{ timeEntryRequests: PaginatedTimeEntryRequests }, QueryTimeEntryRequestsArgs>(TIME_ENTRY_REQUESTS, {
    variables: queryVariables
  })
  return (
    <List sx={{ pt: 0 }}>
      {data?.timeEntryRequests?.results?.map((ter, i) => (
        <ListItem key={i} sx={{ marginBottom: 2 }}>
          <Box display="flex">
            <Avatar sx={{ marginRight: 2 }}>{getInitials(`${ter?.user?.first_name} ${ter?.user?.last_name}`)}</Avatar>
            <Box>
              <Box display="flex" alignItems="end">
                <Typography>{`${ter?.user?.first_name} ${ter?.user?.last_name}`}</Typography>
                <Typography marginLeft={2} color="#667085" fontSize="12px">
                  {ter?.created_at && format(new Date(ter?.created_at), 'h:mm aaa dd MMM yyyy')}
                </Typography>
              </Box>
              <Typography color="#667085" fontSize="14px">
                Requested {ter?.time_entry_id ? 'time edit' : 'new time entry'} on job {ter?.job?.name} on{' '}
                {format(new Date(ter.original_clock_in || ter.requested_clock_in), 'Pp')}
              </Typography>
              <Link onClick={() => setTimeRequestSelected(ter?.id)}>Click to View</Link>
            </Box>
          </Box>
        </ListItem>
      ))}
      {timeRequestSelected && <TimeEntryRequestModal open onClose={() => setTimeRequestSelected(null)} timeEntryId={timeRequestSelected} />}
    </List>
  )
}

export default TimeRequestList
