import { gql } from '@apollo/client'

export const SITES = gql`
  query ($data: SitesQuery!) {
    sites(data: $data) {
      count
      results {
        id
        name
        address
        city
        state
        customer_sites {
          customer {
            id
          }
        }
        zip
      }
    }
  }
`

export const CREATE_SITE = gql`
  mutation ($data: SiteCreateInput!) {
    createSite(data: $data) {
      id
      name
      address
      city
      state
      zip
    }
  }
`

export const UPDATE_SITE = gql`
  mutation ($data: SiteUpdateInput!, $id: ID!) {
    updateSite(data: $data, id: $id) {
      id
      name
      address
      city
      state
      zip
    }
  }
`
