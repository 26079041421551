import { gql } from '@apollo/client'

export const USERS = gql`
  query ($data: UsersQuery) {
    users(data: $data) {
      count
      results {
        id
        email
        role
        inactive
        first_name
        last_name
        phone
      }
    }
  }
`

export const USERS_WITH_ROLES = gql`
  query ($data: UsersQuery) {
    usersWithRoles(data: $data) {
      count
      results {
        id
        email
        role
        inactive
        first_name
        last_name
        phone
        roles {
          id
        }
        roleInfo {
          name
          id
        }
      }
    }
  }
`

export const EMPLOYEES = gql`
  query ($data: EmployeesQuery!) {
    employees(data: $data) {
      count
      results {
        id
        first_name
        last_name
        email
      }
    }
  }
`

export const USER = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      email
      role
      inactive
      first_name
      last_name
      phone
      employee_id
      permissions
      manager_id
      manager {
        id
        first_name
        last_name
      }
    }
  }
`

export const USER_FINANCIAL_TOTALS = gql`
  query ($data: UserFinancialTotalInput!) {
    userFinancialTotals(data: $data) {
      total
      results {
        total_regular_hours
        total_overtime_hours
        total_cost
        user_id
        first_name
        last_name
        rate_type
      }
    }
  }
`
export const USER_JOB_FINANCIAL_TOTALS = gql`
  query ($data: UserFinancialTotalInput!) {
    userJobFinancialTotals(data: $data) {
      total
      results {
        total_regular_hours
        total_overtime_hours
        total_cost
        user_id
        job_id
        name
        first_name
        last_name
        rate_type
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation ($user: UserInput) {
    updateUser(user: $user) {
      id
      email
      role
      inactive
      first_name
      last_name
      phone
      employee {
        id
      }
      manager_id
    }
  }
`
export const CREATE_USER = gql`
  mutation ($user: UserCreateInput) {
    createUser(user: $user) {
      id
    }
  }
`
