import { useMutation, useQuery } from '@apollo/client'
import { CalendarToday, WorkOutline } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled, Typography } from '@mui/material'
import { differenceInMinutes, format } from 'date-fns'
import { useMemo } from 'react'
import toast from 'react-hot-toast'
import { GET_TIME_ENTRY_BY_ID, TIME_ENTRY_REQUEST_STATUS, TIME_ENTRY_REQUESTS, TIME_REQUEST_BY_ID } from 'src/apollo/queries'
import useAuth from 'src/hooks/useAuth'
import X from 'src/icons/X'
import { TimeEntry, TimeEntryRequest } from 'src/types/__generated__/typescript-operations'

type ITimeEntryRequestModal = {
  open: boolean
  onClose: () => void
  timeEntryId: String
}
const PREFIX = 'TimeEntryRequestModal'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  close: `${PREFIX}-close`,
  subtext: `${PREFIX}-subtext`,
  jobDate: `${PREFIX}-jobDate`,
  greyPill: `${PREFIX}-greyPill`,
  oldTimePill: `${PREFIX}-oldTimePill`,
  newTimePill: `${PREFIX}-newTimePill`,
  duration: `${PREFIX}-duration`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.root}`]: {
    padding: theme.spacing(1)
  },
  [`& .${classes.title}`]: {
    fontSize: '20px',
    fontWeight: '500',
    color: 'black'
  },
  [`& .${classes.close}`]: {
    alignItems: 'flex-start',
    color: 'black'
  },
  [`& .${classes.subtext}`]: {
    fontSize: '16px',
    fontWeight: '400',
    color: 'black'
  },

  [`& .${classes.jobDate}`]: {
    fontWeight: '600',
    fontSize: '16px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  [`& .${classes.greyPill}`]: {
    backgroundColor: '#F4F5F9',
    borderRadius: '12px',
    minWidth: '285px',
    minHeight: '35px',
    alignItems: 'center'
  },
  [`& .${classes.oldTimePill}`]: {
    borderRadius: '12px',
    backgroundColor: '#FEF3F2',
    marginLeft: theme.spacing(1),
    padding: '3px',
    fontSize: '12px'
  },
  [`& .${classes.newTimePill}`]: {
    borderRadius: '12px',
    backgroundColor: '#ECFDF3',
    color: '#6CC5A9',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: '3px',
    fontSize: '12px'
  },
  [`& .${classes.duration}`]: {
    fontWeight: '400',
    fontSize: '14px',
    color: '#65728B',
    marginRight: '3px'
  }
}))

const TimeEntryRequestModal = (props: ITimeEntryRequestModal) => {
  const { user } = useAuth()
  const { open, onClose, timeEntryId } = props
  const [updateTimeRequest] = useMutation(TIME_ENTRY_REQUEST_STATUS, {
    onCompleted: (data) => toast.success(`Time Change ${data.timeEntryRequestStatus?.status}`),
    onError: (e) => toast.error(`Error ${String(e.message)}`),
    refetchQueries: [TIME_ENTRY_REQUESTS]
  })
  const { data } = useQuery<{ timeEntryRequestById: TimeEntryRequest }>(TIME_REQUEST_BY_ID, { variables: { id: timeEntryId }, skip: !timeEntryId })

  const { data: timeEntry } = useQuery<{ getTimeEntryById: TimeEntry }>(GET_TIME_ENTRY_BY_ID, {
    variables: { id: data?.timeEntryRequestById?.time_entry_id },
    skip: !data?.timeEntryRequestById?.time_entry_id
  })

  const timeRequest: TimeEntryRequest = useMemo(() => data?.timeEntryRequestById, [data?.timeEntryRequestById])
  const isEdit = !!timeRequest?.original_clock_in
  const oldDuration = useMemo(() => {
    if (!timeRequest?.original_clock_in || !timeRequest?.original_clock_out) return null
    const clock_in = new Date(timeRequest.original_clock_in)
    const clock_out = new Date(timeRequest.original_clock_out)
    return differenceInMinutes(clock_out, clock_in)
  }, [timeRequest])

  const newDuration = useMemo(() => {
    if (!timeRequest?.requested_clock_in || !timeRequest?.requested_clock_out) return null
    const clock_in = new Date(timeRequest.requested_clock_in)
    const clock_out = new Date(timeRequest.requested_clock_out)
    return differenceInMinutes(clock_out, clock_in)
  }, [timeRequest])

  const jobNameChangeRequested = timeEntry && data?.timeEntryRequestById.job?.id !== timeEntry?.getTimeEntryById.job_id

  const onSubmit = (status) => {
    updateTimeRequest({
      variables: {
        data: {
          id: timeRequest?.id,
          status
        }
      }
    })
    onClose()
  }

  return (
    <StyledDialog open={open} onBackdropClick={() => onClose()} onClose={() => onClose()}>
      <Box display="flex" justifyContent="space-between">
        <DialogTitle className={classes.title}>{isEdit ? 'Time Change Request' : 'New Time Request'}</DialogTitle>
        <IconButton className={classes.close} onClick={() => onClose()}>
          <X />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography className={classes.subtext}>
          {timeRequest?.user?.first_name} {timeRequest?.user?.last_name} requested a {isEdit ? 'time change' : 'new time entry'}
        </Typography>
        <Box display="flex" className={classes.jobDate}>
          <WorkOutline sx={{ marginRight: 1 }} />
          {jobNameChangeRequested && ` ${timeEntry?.getTimeEntryById.job.name} / `} {timeRequest?.job?.name}
          <CalendarToday sx={{ marginLeft: 5, marginRight: 1 }} />
          {timeRequest?.requested_clock_in && format(new Date(timeRequest?.requested_clock_in), 'M/dd/yy')}
        </Box>
        {isEdit && (
          <Box display="flex" className={classes.greyPill}>
            <Typography color="error" className={classes.oldTimePill}>
              Old Time
            </Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: '500', paddingX: 3 }}>
              {timeRequest?.original_clock_in && format(new Date(timeRequest?.original_clock_in), 'h:mm aaa - ')}
              {timeRequest?.original_clock_out && format(new Date(timeRequest?.original_clock_out), 'h:mm aaa')}
            </Typography>
            <Typography className={classes.duration}>
              {`(${Math.floor((oldDuration || 0) / 60)}h  & ${((oldDuration || 0) % 60).toFixed(0)}m)`}
            </Typography>
          </Box>
        )}
        <Box display="flex" className={classes.greyPill} marginTop={2} marginBottom={5}>
          <Typography className={classes.newTimePill}>New Time</Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: '500', paddingX: 2 }}>
            {timeRequest?.requested_clock_in && format(new Date(timeRequest?.requested_clock_in), 'h:mm aaa - ')}
            {timeRequest?.requested_clock_out && format(new Date(timeRequest?.requested_clock_out), 'h:mm aaa')}
          </Typography>
          <Typography className={classes.duration}>
            {`(${Math.floor((newDuration || 0) / 60)}h & ${((newDuration || 0) % 60).toFixed(0)}m)`}
          </Typography>
        </Box>
        <Typography>Note</Typography>
        <Typography
          sx={{
            width: '310px',
            height: '66px',
            borderRadius: '8px',
            border: '1px solid #D0D5DD',
            padding: '8px'
          }}
        >
          {timeRequest?.reason || 'no note'}
        </Typography>
      </DialogContent>
      {user.id !== timeRequest?.user_id && (
        <DialogActions>
          <Button
            sx={{
              background: '#EAECF0',
              borderRadius: '100px',
              color: '#65728B',
              padding: '10px 24px'
            }}
            onClick={() => onSubmit('denied')}
          >
            Reject
          </Button>
          <Button
            sx={{
              background: '#0F1010',
              borderRadius: '100px',
              color: '#FFFFFF',
              padding: '10px 24px',
              ':hover': { background: '#EAECF0', color: 'black' }
            }}
            onClick={() => onSubmit('approved')}
          >
            Accept
          </Button>
        </DialogActions>
      )}
    </StyledDialog>
  )
}

export default TimeEntryRequestModal
