import { styled } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { AppBar, Toolbar, Typography, Link } from '@mui/material'
import Logo from 'src/components/Logo'

const PREFIX = 'Topbar'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`
}

const StyledAppBar = styled(AppBar)(() => ({
  [`&.${classes.root}`]: {
    boxShadow: 'none',
    backgroundColor: 'white'
  },

  [`& .${classes.title}`]: {
    color: '#F2BC00',
    marginLeft: 15,
    fontWeight: 'bold',
    fontStyle: 'italic'
  }
}))

const Topbar = (props: any) => {
  const { className, ...rest } = props

  return (
    <StyledAppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Logo />
        <Link component={RouterLink} to="/" underline="none">
          <Typography variant="h4" className={classes.title}>
            FinBuild
          </Typography>
        </Link>
      </Toolbar>
    </StyledAppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string
}

export default Topbar
