import React, { createContext, useEffect, useState } from 'react'
import type { FC, ReactNode } from 'react'
import jwtDecode from 'jwt-decode'
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client'
import { getRefreshToken, setTokens } from 'src/utils/auth'
import { COMPANY, LOGOUT, QUERY_GET_CURRENT_USER, SIGN_IN } from 'src/apollo/queries'
import type { User } from 'src/types/user'
import toast from 'react-hot-toast'
import { Company, Permissions } from 'src/types/__generated__/typescript-operations'

interface AuthState {
  isInitialized: boolean
  isAuthenticated: boolean
  isAdmin: boolean
  permissions: Permissions[]
  user: User | null
  company: Company | null
}

interface AuthContextValue extends AuthState {
  method: 'JWT'
  login: (email: string, password: string) => Promise<any>
  logout: () => void
  register: (email: string, name: string, password: string) => Promise<void>
}

interface AuthProviderProps {
  children: ReactNode
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isAdmin: false,
  user: null,
  permissions: [],
  company: null
}

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false
  }

  const decoded: any = jwtDecode(accessToken)
  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve()
})

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const client = useApolloClient()
  const [signIn] = useMutation(SIGN_IN)
  const [refetchCompany] = useLazyQuery(COMPANY, {
    // fetchPolicy: 'cache-and-network',
    // notifyOnNetworkStatusChange: true
  })
  const [log_out] = useMutation(LOGOUT)
  const [state, setState] = useState<AuthState>({
    isInitialized: false,
    isAuthenticated: false,
    isAdmin: false,
    user: null,
    permissions: [],
    company: null
  })

  const login = async (email: string, password: string) => {
    try {
      const res = await signIn({
        variables: {
          email,
          password
        },
        fetchPolicy: 'no-cache'
      })
      setTokens(res.data.signIn.token, res.data.signIn.refreshToken)
      const { data: company } = await refetchCompany()
      const auth = {
        ...state,
        isInitialized: true,
        isAuthenticated: true,
        isAdmin: res.data?.signIn.user.role === 'admin',
        permissions: res.data.signIn.user.permissions,
        user: res.data.signIn.user
      }

      setState({
        ...state,
        isInitialized: true,
        isAuthenticated: true,
        isAdmin: res.data?.signIn.user.role === 'admin',
        permissions: res.data.signIn.user.permissions,
        user: res.data.signIn.user,
        company: company?.company
      })
      return auth
    } catch (e) {
      return false
    }
  }

  const logout = () => {
    const refreshToken = getRefreshToken()
    log_out({ variables: { refreshToken } })
      .then(() => {
        setTokens('', '')
        setState({
          ...state,
          isInitialized: true,
          isAuthenticated: false,
          isAdmin: false,
          user: null,
          company: null
        })
        // localStorage.clear();
        // client.cache.reset()
        // client.clearStore()
      })
      .catch(() => toast.error('Error logging out'))
  }
  const register = async (_email: string, _name: string, _password: string) => {}

  useEffect(() => {
    const initialise = async () => {
      const accessToken = window.localStorage.getItem('token')

      if (accessToken && isValidToken(accessToken)) {
        const { data: company } = await refetchCompany()
        client
          .query({
            query: QUERY_GET_CURRENT_USER,
            fetchPolicy: 'no-cache'
          })
          .then((t) => {
            // Token most likely valid but not for the server
            if (!t.data.getCurrentUser) {
              setState({
                ...state,
                isInitialized: true,
                isAuthenticated: false,
                company: null
              })
            } else {
              setState({
                ...state,
                isInitialized: true,
                isAuthenticated: true,
                isAdmin: t.data?.getCurrentUser.role === 'admin',
                user: t.data.getCurrentUser,
                permissions: t.data.getCurrentUser.permissions,
                company: company?.company
              })
            }
          })
          .catch(() => {
            setState({
              ...state,
              isInitialized: true,
              isAuthenticated: false,
              company: null
            })
          })
      } else {
        setState({
          ...state,
          isInitialized: true,
          isAdmin: false,
          permissions: [],
          isAuthenticated: false,
          company: null
        })
      }
    }

    initialise()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
